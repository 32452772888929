// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
    margin: 0;
    padding: 0;
    height: 100%;
  
  }
  
  iframe {
    border: none; /* Remove border */
  }
  
  .feedback-container {
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    
  }
  `, "",{"version":3,"sources":["webpack://./src/Feedback/feedback.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,YAAY;;EAEd;;EAEA;IACE,YAAY,EAAE,kBAAkB;EAClC;;EAEA;IACE,YAAY,EAAE,wBAAwB;IACtC,aAAa,EAAE,yBAAyB;;EAE1C","sourcesContent":["html, body {\n    margin: 0;\n    padding: 0;\n    height: 100%;\n  \n  }\n  \n  iframe {\n    border: none; /* Remove border */\n  }\n  \n  .feedback-container {\n    width: 100vw; /* Full viewport width */\n    height: 100vh; /* Full viewport height */\n    \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// LanguageContext.js
import React, { createContext, useState, useEffect } from 'react';
import { BASE_URL } from '../config';
import { getSessionData } from '../utils/session';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState('');
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const sessionData = getSessionData();
  const user_name = sessionData?.username;

  useEffect(() => {
    const fetchCurrentLanguage = async () => {
      const payload = { user_name: user_name };
      try {
        const response = await fetch(`${BASE_URL}/api/get_language`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
        const data = await response.json();
        const { language_selected } = data.response;
       
        setCurrentLanguage(language_selected);
        // console.log(currentLanguage)
      } catch (error) {
        console.error('Error fetching current language:', error);
      }
    };

    const fetchAvailableLanguages = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/list_language`);
        const data = await response.json();
        const languages = data.response;
      
        setAvailableLanguages(languages);
      } catch (error) {
        console.error('Error fetching available languages:', error);
      }
    };

    fetchCurrentLanguage();
    fetchAvailableLanguages();
  }, [user_name]);

  const updateLanguage = async (language,userName) => {
    const currentDateTime = new Date().toISOString();
    const payload = {
      user_name: userName,
      language: language,
      timestamp: currentDateTime,
    };
// console.log("paylod for langauage",payload)
    try {
      const response = await fetch(`${BASE_URL}/api/update_language`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.response === 'Done') {
        setCurrentLanguage(language);
      } else {
        console.error('Failed to update language.');
      }
    } catch (error) {
      console.error('Error updating language:', error);
    }
  };

  return (
    <LanguageContext.Provider value={{ currentLanguage, availableLanguages, updateLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return React.useContext(LanguageContext);
};

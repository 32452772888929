import React, { useState, useEffect } from 'react';
import { updatebrowserlocation } from './api'; // Adjust the import path as needed

const LocationFetcher = ({ userName }) => {
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    error: null
  });

  useEffect(() => {
    const fetchLocation = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            setLocation({
              latitude,
              longitude,
              error: null
            });

            try {
              await updatebrowserlocation(userName, latitude, longitude);
            } catch (error) {
              console.error('Error updating browser location:', error);
            }
          },
          (error) => {
            setLocation({
              latitude: null,
              longitude: null,
              error: error.message
            });
          }
        );
      } else {
        setLocation({
          error: 'Geolocation is not supported by this browser.',
        });
      }
    };

    fetchLocation();
  }, [userName]);

  return null;  // No need to render anything to the UI
};

export default LocationFetcher;

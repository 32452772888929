import React from 'react';
import { Box, Typography } from '@mui/material';
import NavBar from '../HomePage/NavBar_home';
import { useNavigate } from 'react-router-dom';
import "./feedback.css"
const Feedback = () => {
  const navigate = useNavigate();

  return (
  
    <Box sx={{ minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
      <NavBar />
      <Box sx={{
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '2rem',
        margin: '2rem auto',
        maxWidth: '100%',
        height: 'calc(100vh - 4rem)',  // Adjust based on padding/margin
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',  // Align items to the start
        position: 'relative',
        overflow: 'hidden'
      }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
          Your feedback is invaluable to us!
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ marginBottom: '2rem', color: '#666' }}>
          We strive to enhance our services continually, and your insights play a crucial role in shaping our future.
          Please take a moment to share your thoughts, suggestions, or experiences with us. Your feedback empowers us
          to deliver an even better experience for you. Thank you for being a part of our journey towards improvement!
        </Typography>
        <Box sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden'
        }}>
          <iframe
            src="https://share.hsforms.com/1I5I-i45fTSiUJHkn6zpjKw4y1so"
            width="100%"
            height="100%"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            scrolling="yes"
            style={{ border: 'none', borderRadius: '4px' }}
            allowFullScreen
          ></iframe>
        </Box>
      </Box>
    </Box>
  );
};

export default Feedback;

import React from 'react';
import ReactApexChart from 'react-apexcharts';

class ApexChart_2 extends React.Component {
  constructor(props) {
    super(props);

    const colors = [
      'rgba(82, 4, 85, 0.781)', // Color for the first area
      'rgb(120, 6, 146)', // Color for the second area
      'rgb(221, 110, 247)', // Color for the third area
      'rgb(190, 134, 210)', // Color for the fourth area
      'rgba(197, 150, 199, 0.705)', // Color for the fifth area
      'rgba(140, 106, 148, 0.705)', // Color for the sixth area
      // Add more colors if you have more areas
    ];

    this.state = {
      options: {
        chart: {
          type: 'polarArea',
        },
        fill: {
          opacity: 1,
        },
        stroke: {
          width: 1,
          colors: undefined,
        },
        yaxis: {
          show: false,
        },
        legend: {
          position: 'bottom',
        },
        plotOptions: {
          polarArea: {
            rings: {
              strokeWidth: 0,
            },
            spokes: {
              strokeWidth: 0,
            },
          },
        },
        theme: {
          monochrome: {
            enabled: false, // Disable monochrome to apply custom colors
          },
        },
        colors: colors, // Apply custom colors
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 350, // Adjust chart width for mobile view
              },
              legend: {
                fontSize: '10px', // Adjust legend font size for mobile view
                itemMargin: {
                  horizontal: 5,
                  vertical: 2,
                },
              },
            },
          },
        ],
      },
    };
  }

  render() {
    const { scores, labels } = this.props;

    return (
      <div id="chart">
        <ReactApexChart
          options={{ ...this.state.options, labels }}
          series={scores}
          type="polarArea"
          width={400}
        />
      </div>
    );
  }
}

export default ApexChart_2;




import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardActionArea, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import the tick icon
import NavBar from '../HomePage/NavBar_home';
import "./Assessment.css";
import { BASE_URL } from '../config';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';


const MyAssessment = () => {
  const navigate = useNavigate();
  const userInfo=JSON.parse(sessionStorage.getItem('userInfo'));
  const user_name=userInfo.username
  const [selectedAssessmentType, setSelectedAssessmentType] = useState('');
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const [pendingAssessments, setPendingAssessments] = useState([]);
  const [completedAssessments, setCompletedAssessments] = useState([]);

  useEffect(() => {
    const fetchAssessmentDetails = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/list_available_assessmets_mb`);
        const data = await response.json();
        if (data.status === 'Success.') {
          setAssessmentDetails(data.response);
        } else {
          console.error('Failed to fetch assessment details:', data.process_status);
        }
      } catch (error) {
        console.error('Error fetching assessment details:', error);
      }
    };

    const checkAssessmentAlert = async() => {
      try {
        const response = await fetch(`${BASE_URL}/api/assessment_alert_mb`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            user_name: user_name // Use actual user_name
          })
        });
        const data = await response.json();
        // console.log('Assessment Alert Response:', data);
        const assessmentsGiven = data.response.assessments_given_by_user;
        setCompletedAssessments(assessmentsGiven);
        // console.log(completedAssessments)
      } catch (error) {
        console.error('Error fetching assessment alert:', error);
      }
    };
    
    checkAssessmentAlert();
    fetchAssessmentDetails();
    
  }, []);

  const handleCardClick = (type) => {
    setSelectedAssessmentType(type);
    navigate(`/assessment/${type}`);
  };

  return (
    <Box>
      <NavBar />

      <div className="center-container">
        <h4 className="custom-heading">Emotional Well-being Assessment</h4>
        <p className="custom-body">
          Participate in our intelligent emotional well-being assessment sessions to gain insights into your emotional state and receive personalized feedback.
        </p>
      </div>
{console.log(completedAssessments)}
      <Grid container sx={{ marginTop: 8, backgroundColor: "var(--color-snow)" }}>
        {assessmentDetails.map((assessment, index) => {
const assessmentName = Object.keys(assessment)[0].trim()         
 const writeupKey = `EA-${index + 1}_writeup`;
          const writeup = assessment[writeupKey];
          const type = `EA-${index + 1}`;
          const isCompleted = completedAssessments.map(item => item.trim()).includes(assessmentName);
          // const isCompleted = completedAssessments.includes(assessmentName); // Check if the assessment is completed
          // console.log(assessmentName,isCompleted)
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={type} className="grid-item">
              <Card className="custom-card">
                <CardActionArea 
                  onClick={() => handleCardClick(type)} 
                  className="card-action"
                >
                  <CardContent className="card-content">
                    {isCompleted && (
 <>
 <CheckCircleIcon 
   sx={{ position: 'absolute', top: 2, right: 8, color: 'white', zIndex: 10 }} 
 />
  <Typography 
   variant="caption" 
   sx={{ position: 'absolute', top: 22, right: 5, color: 'white', zIndex: 10 ,fontWeight:"bold"}}
 >
  Taken
 </Typography>
</>
                      // <CheckCircleIcon 
                      //   sx={{ position: 'absolute', top: 8, right: 8, color: 'green', zIndex: 10 }} 
                      // />
                    )}
                    <Typography variant='h5' sx={{ marginBottom: 2, marginTop: 2, fontFamily: 'Roboto', fontWeight: 'bold' }}>
                      {assessmentName}
                    </Typography>
                    <div className="description-border">
                      <Typography variant="body2" sx={{ marginBottom: 2, marginTop: 2, fontFamily: 'Roboto' }}>
                        {writeup}
                      </Typography>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <div className="home-footer">
  <p>
    BAIA: Your AI-Powered Emotional Support Assistant{' '}
    <a
      href="https://www.baia.ai/terms-and-condition/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue' }}
    >
      Terms
    </a>,{' '}
    <a
      href="https://www.baia.ai/disclaimer/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue' }}
    >
      Disclaimer
    </a>{' '}
    and{' '}
    <a
      href="https://www.baia.ai/privacy-policy/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue' }}
    >
      Privacy Policy
    </a> || Version: Beta-4.0
  </p>
</div>

    </Box>
  );
};

export default MyAssessment;

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { InputBase, IconButton, Popover, Box } from '@mui/material';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { EmojiEmotions as EmojiIcon, Send as SendIcon, Mic as MicIcon } from '@mui/icons-material';
import Picker from '@emoji-mart/react';
import { useTheme, useMediaQuery } from '@mui/material';
import SubscriptionModal from '../utils/SubscriptionModal';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';

import nlp from 'compromise';

const MessageInput = ({ onSend, isRenderingRef }) => {
  const [message, setMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const abortControllerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const recognitionRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const [lastTranscript, setLastTranscript] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(sessionStorage.getItem('current_language') || 'English');

  const languagesPool = {
    French: "fr-FR",
    English: "en-US",
    Spanish: "es-ES",
  };
  const languageCode = languagesPool[selectedLanguage];

  // // Initialize SpeechRecognition
  // const initSpeechRecognition = () => {
  //   if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
  //     const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  //     const recognition = new SpeechRecognition();
  //     recognition.continuous = true; // Allow continuous speech input
  //     recognition.interimResults = true; // Capture intermediate results
  //       //   recognition.lang = 'en-US';
  //     // recognition.lang = 'fr-FR'
  //     recognition.lang = languageCode
  
  //     recognition.onstart = () => {
  //       setIsListening(true);
  //     };
  
  //     recognition.onresult = (event) => {
  //       let interimTranscript = '';
  //       let finalTranscript = '';
  
  //       for (let i = event.resultIndex; i < event.results.length; i++) {
  //         const result = event.results[i];
  //         if (result.isFinal) {
  //           finalTranscript += result[0].transcript;
  //         } else {
  //           interimTranscript += result[0].transcript;
  //         }
  //       }
  
  //       // Update the message with final and interim transcripts
  //       setMessage(finalTranscript + interimTranscript);
  //     };
  
  //     recognition.onend = () => {
  //       setIsListening(false);
  //       recognitionRef.current = null;
  //     };
  
  //     recognitionRef.current = recognition;
  //   } else {
  //     console.error('SpeechRecognition is not supported in this browser.');
  //   }
  // };


  //  Code added by Gourav
  const initSpeechRecognition = () => {
    if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      recognition.continuous = true; // Allow continuous speech input
      recognition.interimResults = true; // Capture intermediate results
      //   recognition.lang = 'en-US';
      // recognition.lang = 'fr-FR'
      recognition.lang = languageCode;
  
      recognition.onstart = () => {
        setIsListening(true);
      };
  
      recognition.onresult = (event) => {
        let interimTranscript = '';
        let finalTranscript = '';
  
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const result = event.results[i];
          if (result.isFinal) {
            finalTranscript += result[0].transcript;
          } else {
            interimTranscript += result[0].transcript;
          }
        }
  
        // Apply basic grammar and punctuation correction
        const correctedFinalTranscript = correctText(finalTranscript);
        const correctedInterimTranscript = correctText(interimTranscript);
  
        // Update the message with final and interim transcripts
        setMessage(correctedFinalTranscript + correctedInterimTranscript);
      };
  
      recognition.onend = () => {
        setIsListening(false);
        recognitionRef.current = null;
      };
  
      recognitionRef.current = recognition;
    } else {
      console.error('SpeechRecognition is not supported in this browser.');
    }
  };
  
  // Function to apply basic grammar and punctuation correction
  const correctText = (text) => {
    let doc = nlp(text);
    
    // Convert the sentences to proper casing and add basic punctuation.
    let correctedText = doc.sentences().toLowerCase().out('text'); // Ensure all text is lowercased.
    correctedText = doc.sentences().toTitleCase().out('text'); // Correct casing for sentences.
    
    // Alternatively, you can manually add periods at the end of sentences if they are missing.
    // correctedText = correctedText.replace(/([a-zA-Z0-9])(\s|$)/g, '$1. ');
  
    return correctedText.trim();
  };
  //  Code ends here 


  const handleMicClick = () => {
    if (recognitionRef.current) {
      // Stop recognition if it's already running
      recognitionRef.current.stop();
    } else {
      // Start recognition
      initSpeechRecognition();
      recognitionRef.current.start();
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const checkSubscriptionStatus = () => {
    const subscriptionData = JSON.parse(sessionStorage.getItem('central_detail')) || {};
    const intial_sub = JSON.parse(sessionStorage.getItem('initial_subscription')).enable_casual_chat_session_status;

    if (intial_sub) {
      return { canProceed: true, subscriptionData };
    }

    if (subscriptionData.subscription_status) {
      if (subscriptionData.daily_time_limit_quota) {
        return { canProceed: false, text: "Your daily time limit quota is over. Please subscribe to the Pro version." };
      }

      return { canProceed: true, subscriptionData };
    }

    return { canProceed: false, text: "No central subscription." };
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
    setCursorPosition(event.target.selectionStart);
  };

  const handleKeyPress = (event) => {
    const { canProceed } = checkSubscriptionStatus();

    if (event.key === 'Enter') {
      if (!canProceed) {
        handleOpenModal();
        return;
      }
      if (isMobile || event.shiftKey) {
        event.preventDefault(); // Prevent default Enter behavior
        const newMessage = message.slice(0, cursorPosition) + '\n' + message.slice(cursorPosition);
        setMessage(newMessage);
        setCursorPosition(cursorPosition + 1);
      } else {
        event.preventDefault();
        handleSend();
      }
    }
  };

  const handleSend = () => {
    const { canProceed } = checkSubscriptionStatus();

    if (message.trim()) {
      if (!canProceed) {
        handleOpenModal();
        return;
      }
      abortControllerRef.current = new AbortController();
      onSend(message, abortControllerRef.current);
      setMessage('');
    }
  };

  const handleStopRendering = () => {
    isRenderingRef.current = false;
    sessionStorage.setItem('isRendering', 'false');
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleEmojiSelect = (emoji) => {
    const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
    setMessage(newMessage);
    setCursorPosition(cursorPosition + emoji.native.length);
    handleClosePopover();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'emoji-popover' : undefined;

  const details_product_name=JSON.parse(sessionStorage.getItem('central_subscription')).product_name
  const initial_details = JSON.parse(sessionStorage.getItem('initial_subscription'));
  const initialcasualChatStatus = initial_details.enable_casual_chat_session_status;

  const shouldShowMicIcon = (details_product_name === 'PRO' || details_product_name === 'ADVACED' || initialcasualChatStatus) &&
                              sessionStorage.getItem('consent') === 'true';


  return (
    <div className="text-field">
       <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <Picker set="apple" onEmojiSelect={handleEmojiSelect} />
        </Box>
      </Popover>
      <div className="input-container" style={{ display: 'flex', alignItems: 'center' }}>
        <div
          className="icons-container"
          style={{
            position: 'absolute',
            fontFamily: 'Roboto',
            left: '0px',
            bottom: '5px',
            display: 'flex',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <IconButton onClick={handleOpenPopover} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
            <EmojiIcon sx={{ color: 'rgb(65, 6, 83)' }} />
          </IconButton>
        </div>
        <InputBase
          placeholder="Type your message..."
          value={message}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          multiline
          sx={{
            zIndex: 9999,
            flex: 1,
            width: 'calc(100% - 130px)',
            paddingLeft: 3,
            minHeight: '51px',
            maxHeight: '150px',
            borderRadius: '20px',
            backgroundColor: 'var(--color-white)',
            fontSize: '16px',
            padding: '5px 10px', // Adjust padding to reduce space
            marginLeft: '20px',
            marginRight: '100px',
            overflowY: 'auto',
            wordBreak: 'break-word',
            fontFamily: 'Roboto',
            lineHeight: 1.5, // Adjust line-height to reduce space between lines
          }}
        />
        <div
          className="icons-container"
          style={{
            position: 'absolute',
            right: '5px',
            bottom: '5px',
            display: 'flex',
            alignItems: 'center',
            zIndex: 1,
          }}
          >
          {shouldShowMicIcon && (
            <IconButton
            onClick={handleMicClick}
            sx={{
              fontSize: isMobile ? 'medium' : 'large',
              borderRadius: '50%',
              padding: isListening ? '8px' : '0',
              backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
              border: isListening ? '2px solid rgb(65, 6, 83)' : 'none',
              '&:hover': {
                backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
              },
            }}
          >
            {isListening ? (
              <MicIcon sx={{ color: 'white' }} />
            ) : (
              <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
            )}
          </IconButton>
        )}

          <IconButton
            onClick={isRenderingRef.current ? handleStopRendering : handleSend}
            sx={{ fontSize: isMobile ? 'medium' : 'large' }}
          >
            {isRenderingRef.current ? (
              <StopCircleIcon sx={{ color: 'rgb(65, 6, 83)' }} />
            ) : (
              <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
            )}
          </IconButton>
        </div>
      </div>
      <SubscriptionModal open={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

MessageInput.propTypes = {
  onSend: PropTypes.func.isRequired,
  isRenderingRef: PropTypes.object.isRequired,
};

export default MessageInput;


import { memo } from "react";
import PropTypes from "prop-types";
import "./Image1.css";

const Image1 = memo(({ className = "" }) => {
  return (
    <div className={`image ${className}`}>
      <div className="image-placeholder" />
      <div className="active-indicator" />
      <img
        className="inactive-indicator-icon"
        alt=""
        src="/rectangle-37@2x.png"
      />
    </div>
  );
});

Image1.propTypes = {
  className: PropTypes.string,
};

export default Image1;

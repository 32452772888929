// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
    background-color: rgba(187, 161, 250, 0.144);
  }
  .loading-content{
    display: flex;
    flex-direction: column;
  }
  .loading-image{
    
    /* border: 1px solid rgba(37, 1, 34, 0.185); */
    border-radius: 5px;
    width:100%;
  height: 100%;
  background-color: white;
  /* box-shadow: 0 2px 2px rgba(98, 67, 124, 0.1), 2px 2px 2px rgba(0, 0, 0, 0.1); */
  margin-top: 5%;
  }
  .loading-text {
    text-align: center;
    color: rgb(30, 4, 54);
    /* position: absolute; */
     margin-top:1%;
     /* width: 50%; */
    
    }
    @media (max-width: 660px) {
      .loading-content{
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 50%;
      }

      .loading-text {
        color: rgb(30, 4, 54);
        /* position: absolute; */
         margin-top:1%;
         font-size: 0.9em;
        
        }



    }`, "",{"version":3,"sources":["webpack://./src/css/loading.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,mBAAmB;IACjB,4CAA4C;EAC9C;EACA;IACE,aAAa;IACb,sBAAsB;EACxB;EACA;;IAEE,8CAA8C;IAC9C,kBAAkB;IAClB,UAAU;EACZ,YAAY;EACZ,uBAAuB;EACvB,kFAAkF;EAClF,cAAc;EACd;EACA;IACE,kBAAkB;IAClB,qBAAqB;IACrB,wBAAwB;KACvB,aAAa;KACb,gBAAgB;;IAEjB;IACA;MACE;QACE,aAAa;QACb,sBAAsB;QACtB,iBAAiB;QACjB,kBAAkB;QAClB,eAAe;MACjB;;MAEA;QACE,qBAAqB;QACrB,wBAAwB;SACvB,aAAa;SACb,gBAAgB;;QAEjB;;;;IAIJ","sourcesContent":[".loading-container {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  align-items: center;\n    background-color: rgba(187, 161, 250, 0.144);\n  }\n  .loading-content{\n    display: flex;\n    flex-direction: column;\n  }\n  .loading-image{\n    \n    /* border: 1px solid rgba(37, 1, 34, 0.185); */\n    border-radius: 5px;\n    width:100%;\n  height: 100%;\n  background-color: white;\n  /* box-shadow: 0 2px 2px rgba(98, 67, 124, 0.1), 2px 2px 2px rgba(0, 0, 0, 0.1); */\n  margin-top: 5%;\n  }\n  .loading-text {\n    text-align: center;\n    color: rgb(30, 4, 54);\n    /* position: absolute; */\n     margin-top:1%;\n     /* width: 50%; */\n    \n    }\n    @media (max-width: 660px) {\n      .loading-content{\n        display: flex;\n        flex-direction: column;\n        margin-left: 20px;\n        margin-right: 20px;\n        margin-top: 50%;\n      }\n\n      .loading-text {\n        color: rgb(30, 4, 54);\n        /* position: absolute; */\n         margin-top:1%;\n         font-size: 0.9em;\n        \n        }\n\n\n\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, Typography, Box } from '@mui/material';
import './ConsentForm.css';

const ConsentForm = ({ onSubmit }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleTermsCheckboxChange = (event) => {
    setIsTermsChecked(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isChecked && isTermsChecked) {
      onSubmit();
    } else {
      alert("You must confirm that you are over 18 years old and accept the terms, policy, and disclaimer.");
    }
  };

  return (
    <Box className="form" component="form" onSubmit={handleSubmit}>
      <Typography variant="h5" component="h2" className="consent-title">
        Consent Form
      </Typography>
      <FormControlLabel
        control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} color="primary" />}
        label="I confirm that I am over 18 years old"
        className="consent-checkbox"
      />
      <FormControlLabel
        control={
          <Checkbox 
            checked={isTermsChecked} 
            onChange={handleTermsCheckboxChange} 
            color="primary" 
          />
        }
        label={
          <span>
            I have read and accept the{' '}
            <a href="https://www.baia.ai/terms-and-condition/" target="_blank" rel="noopener noreferrer">
              Terms
            </a>,{' '}
            <a href="https://www.baia.ai/disclaimer/" target="_blank" rel="noopener noreferrer">
              Disclaimer
            </a>, and{' '}
            <a href="https://www.baia.ai/privacy-policy/" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </span>
        }
        className="terms-checkbox"
      />
      <Button type="submit" variant="contained" color="primary" className="consent-submit">
        Submit
      </Button>
    </Box>
  );
};

export default ConsentForm;


// ProfileImageContext.js
import React, { createContext, useState, useContext } from 'react';
import Female from "../images/images.png"
import Male from "../images/baia_new.png"
const ProfileImageContext = createContext();

export const useProfileImageContext = () => useContext(ProfileImageContext);

export const ProfileImageContextProvider = ({ children }) => {
  const [profileImage, setProfileImage] = useState(Male); // Initialize with default image URL or null

  const updateProfileImage = (gender) => {
    // Logic to set different image URLs based on gender
    if (gender === 'Female') {

      setProfileImage(Male); // Replace with actual path
    } else if (gender === 'Male') {
      setProfileImage(Male); // Replace with actual path
    } else {
      setProfileImage(null); // Reset image if no gender selected
    }
  };

  return (
    <ProfileImageContext.Provider value={{ profileImage, updateProfileImage }}>
      {children}
    </ProfileImageContext.Provider>
  );
};



import React, { useState, useEffect ,useContext} from 'react';
import { useNavigate,useLocation  } from 'react-router-dom';
import {Avatar,List, ListItem, ListItemIcon, ListItemText,Drawer,Box, Menu, MenuItem, Typography } from '@mui/material';
import { useProfilePic } from '../context/ProfilePicContext';
import ChatSharpIcon from '@mui/icons-material/ChatSharp';
import AssignmentSharpIcon from '@mui/icons-material/AssignmentSharp';
import InsertChartSharpIcon from '@mui/icons-material/InsertChartSharp';
import PersonIcon from '@mui/icons-material/Person';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import FeedbackIcon from '@mui/icons-material/Feedback';
import LogoutIcon from '@mui/icons-material/Logout';
import "./NavBar_home.css";
import { Badge, IconButton } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';import { BASE_URL} from '../config';
import AuthContext from '../AuthContext';
import MenuIcon from '@mui/icons-material/Menu';
import { getSessionData } from '../utils/session';
import SettingsIcon from '@mui/icons-material/Settings';
import SubscriptionModal from '../utils/SubscriptionModal';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PropTypes from 'prop-types';
import LockIcon from '@mui/icons-material/Lock';
import { fetchFreshChat,fetchAvailedTime,fetchCharacterCode ,fetchAndRenderImage,checkCentralSubscription,checkInitialSubscription} from '../api';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { useTimer } from "../context/TimerContext";
import WatchLaterIcon from '@mui/icons-material/WatchLater';




const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setModalOpen] = useState(false);
  const userinfo=JSON.parse(sessionStorage.getItem('userInfo') ) 
  const user_name = userinfo.username;  
  const email_id=userinfo.email;
  const { loggedIn, userInfo, handleLogin, handleLogout } = useContext(AuthContext);
 
  const [profilePic, setProfilePic] = useState('');
 
  useEffect(() => {

    const storedImageData = localStorage.getItem('image_data');
    if (storedImageData) {
        setProfilePic(storedImageData);
    } 
}, []);
  const [anchorEl, setAnchorEl] = useState(null);


  const  central_sub=JSON.parse(sessionStorage.getItem('central_subscription')).subscription_status
  const chat_sub=JSON.parse(sessionStorage.getItem('central_subscription')).enable_casual_chat_session_status;
  const assessment_sub=JSON.parse(sessionStorage.getItem('central_subscription')).enabled_assesments;
  const analysis_sub=JSON.parse(sessionStorage.getItem('central_subscription')).enabled_mood_analysis
  const chat_active=JSON.parse(localStorage.getItem('chat'))
  const assessment_active=JSON.parse(localStorage.getItem('assessment'))
  const analysis_active=JSON.parse(localStorage.getItem('analysis'))
  const [activeTab, setActiveTab] = useState(location.pathname);
  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);
  

  const intial_sub = JSON.parse(sessionStorage.getItem('initial_subscription')) || {};
  const subscriptionData = JSON.parse(sessionStorage.getItem('central_detail')) || {};

  const { trial_status, product_name, subscription_status, enable_casual_chat_session_status, enabled_mood_analysis, enabled_assesments, assesment_screen_alert, daily_time_limit_quota } = subscriptionData;

 
  const handleNavigation = (path) => {

  
    if (path === '/analysis') {
      if (analysis_active || (product_name === 'ADVACED' && enabled_mood_analysis)) {
        if (!subscription_status) {
          // Subscription is not active
          setModalOpen(true);
        } else if (daily_time_limit_quota) {
          // Daily time limit quota is exceeded
          alert("Your daily time limit quota is over. Please subscribe to the Pro version.");
        } else {
          navigate(path);
          setActiveTab(path);
        }
      } else {
        alert("You need the Advanced subscription and active analysis to access Mood Analysis.");
      }
    } else if (path === '/assessment') {
      if (assessment_active || (product_name === 'ADVACED' && enabled_assesments)) {
        if (!subscription_status) {
          // Subscription is not active
          setModalOpen(true);
        } else if (daily_time_limit_quota) {
          // Daily time limit quota is exceeded
          alert("Your daily time limit quota is over. Please subscribe to the Pro version.");
        } else {
          navigate(path);
          setActiveTab(path);
        }
      } else {
        alert("You need the Advanced subscription and active assessment to access Assessments.");
      }
    }  else {
      navigate(path);
      setActiveTab(path);
    }
  };
  
  // const handleNavigation = (path) => {
  //   const intial_sub=JSON.parse(sessionStorage.getItem('initial_subscription'))
  
  //   const subscriptionData = JSON.parse(sessionStorage.getItem('central_detail')) || {};
  
  //   const { trial_status, product_name, subscription_status, enable_casual_chat_session_status, enabled_mood_analysis, enabled_assesments, assesment_screen_alert, daily_time_limit_quota } = subscriptionData;
  
  //   if (intial_sub) {
  //     navigate(path);
  //     setActiveTab(path);
  //   } else {
  //     // Central subscription checks
     
  //       if (!subscription_status) {
  //         // Subscription is not active
  //         setModalOpen(true);
  //       } else if (daily_time_limit_quota) {
  //         // Daily time limit quota is exceeded
  //         alert("Your daily time limit quota is over. Please subscribe to the Pro version.");
  //       } else {
  //         // Check path-specific conditions
  //         if (path === '/analysis') {
  //           if (product_name === 'ADVACED' && enabled_mood_analysis) {
  //             navigate(path);
  //             setActiveTab(path);
  //           } else {
  //             alert("You need the Advanced subscription to access Mood Analysis.");
  //           }
  //         } else if (path === '/assessment') {
  //           if (product_name === 'ADVACED' && enabled_assesments) {
  //             navigate(path);
  //             setActiveTab(path);
  //           } else {
  //             alert("You need the Advanced subscription to access Assessments.");
  //           }
  //         }
  //        else {
  //           navigate(path);
  //           setActiveTab(path);
  //         }
  //       }
      
  //   }
  // };
  
// console.log(central_sub)

  const [hoveredHome, setHoveredHome] = useState(false);
  const [hoveredAssessment, setHoveredAssessment] = useState(false);
  const [hoveredAnalysis, setHoveredAnalysis] = useState(false);
  const handleMouseEnterHome = () => {
    setHoveredHome(true);
  };
  
  const handleMouseLeaveHome = () => {
    setHoveredHome(false);
  };
  
  const handleMouseEnterAssessment = () => {
    setHoveredAssessment(true);
  };
  
  const handleMouseLeaveAssessment = () => {
    setHoveredAssessment(false);
  };
  
  const handleMouseEnterAnalysis = () => {
    setHoveredAnalysis(true);
  };
  
  const handleMouseLeaveAnalysis = () => {
    setHoveredAnalysis(false);
  };
  
  useEffect(() => {
    return () => {
      const { timerInterval, screenTimeUpdateInterval } = JSON.parse(localStorage.getItem('timerIntervals')) || {};
      clearInterval(timerInterval);
      clearInterval(screenTimeUpdateInterval);
      localStorage.removeItem('timerIntervals');
    };

  }, []);


  const [isNotificationHovered, setIsNotificationHovered] = useState(false);
  const [isNotificationClicked, setIsNotificationClicked] = useState(false);
  const handleMouseEnterNotification = () => {
    setIsNotificationHovered(true);
  };
  
  const handleMouseLeaveNotification = () => {
    setIsNotificationHovered(false);
  };
  
 
  
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [hoveredTab, setHoveredTab] = useState(null);

  const handleMouseEnter = (tab) => {
    setHoveredTab(tab);
  };

  const handleMouseLeave = () => {
    setHoveredTab(null);
  };

  const isMenuOpen = Boolean(anchorEl);

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{
        mt: 6,
        '& .MuiPaper-root': {
          backgroundColor: '', // Change the background color
          borderRadius: '5px',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
          padding: '0px 20px 0px 10px',
        },
      }}
      className="menu" // Apply custom class name from CSS file
    >
       <MenuItem
        className="menuItem"
        style={{
          backgroundColor: activeTab === '/profile' || hoveredTab === '/profile' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
        onClick={() => navigate('/profile')}
        onMouseEnter={() => handleMouseEnter('/profile')}
        onMouseLeave={handleMouseLeave}
      >
        {(activeTab === '/profile' || hoveredTab === '/profile') ? (
          <PersonIcon className="icon" style={{ marginRight: '15px', color: 'rgb(70, 51, 105)' }} />
        ) : (
          <PersonOutlineOutlinedIcon className="icon" style={{ marginRight: '15px', color: 'rgb(70, 51, 105)' }} />
        )}
        Profile
      </MenuItem>
      
      <MenuItem
        className="menuItem"
        style={{
          backgroundColor: activeTab === '/subscription' || hoveredTab === '/subscription' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
        onClick={() => navigate('/subscription')}
        onMouseEnter={() => handleMouseEnter('/subscription')}
        onMouseLeave={handleMouseLeave}
      >
        {(activeTab === '/subscription' || hoveredTab === '/subscription') ? (
          <FeedbackIcon className="icon" style={{ fontSize: 20, marginRight: '15px', color: 'rgb(70, 51, 105)' }} />
        ) : (
          <FeedbackOutlinedIcon className="icon" style={{ fontSize: 20, marginRight: '15px', color: 'rgb(70, 51, 105)' }} />
        )}
        My Subscription
      </MenuItem>
      
      <MenuItem
        className="menuItem"
        style={{
          backgroundColor: activeTab === '/feedback' || hoveredTab === '/feedback' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
        onClick={() => navigate('/feedback')}
        onMouseEnter={() => handleMouseEnter('/feedback')}
        onMouseLeave={handleMouseLeave}
      >
        {(activeTab === '/feedback' || hoveredTab === '/feedback') ? (
          <LoyaltyIcon className="icon" style={{ fontSize: 20, marginRight: '15px', color: 'rgb(70, 51, 105)' }} />
        ) : (
          <LoyaltyOutlinedIcon className="icon" style={{ fontSize: 20, marginRight: '15px', color: 'rgb(70, 51, 105)' }} />
        )}
        Feedback
      </MenuItem>
      
      <MenuItem
        className="menuItem"
        style={{
          backgroundColor: activeTab === '/manage-account' || hoveredTab === '/manage-account' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
        onClick={() => navigate('/manage-account')}
        onMouseEnter={() => handleMouseEnter('/manage-account')}
        onMouseLeave={handleMouseLeave}
      >
        {(activeTab === '/manage-account' || hoveredTab === '/manage-account') ? (
          <ManageAccountsIcon className="icon" style={{ fontSize: 20, marginRight: '15px', color: 'rgb(70, 51, 105)' }} />
        ) : (
          <ManageAccountsOutlinedIcon className="icon" style={{ fontSize: 20, marginRight: '15px', color: 'rgb(70, 51, 105)' }} />
        )}
        Manage Account
      </MenuItem>

      <MenuItem
        className="menuItem"
        style={{
          backgroundColor: activeTab === '/setting' || hoveredTab === '/setting' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
        onClick={() => navigate('/setting')}
        onMouseEnter={() => handleMouseEnter('/setting')}
        onMouseLeave={handleMouseLeave}
      >
        {(activeTab === '/setting' || hoveredTab === '/setting') ? (
          <SettingsIcon className="icon" style={{ fontSize: 20, marginRight: '15px', color: 'rgb(70, 51, 105)' }} />
        ) : (
          <SettingsOutlinedIcon className="icon" style={{ fontSize: 20, marginRight: '15px', color: 'rgb(70, 51, 105)' }} />
        )}
        Settings
      </MenuItem>
      
      <MenuItem
        className="menuItem"
        style={{
          backgroundColor: activeTab === '/logout' || hoveredTab === '/logout' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
        onClick={handleLogout}
        onMouseEnter={() => handleMouseEnter('/logout')}
        onMouseLeave={handleMouseLeave}
      >
        
        <LogoutIcon className="icon" style={{ marginRight: '15px', color: 'rgb(70, 51, 105)' }} />
        Logout
      </MenuItem>
    </Menu>
  );
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const toggleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
    // setHoveredTab(null);
  };
  const [anchorEl_1, setAnchorEl_1] = useState(null);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/get_notification`);
      const data = await response.json();
      const { notification_message, message_type } = data.response;

      // Prepare new notification object
      const newNotification = {
        id: notifications.length + 1,
        message: notification_message,
        type: message_type
      };

      // Update notifications state with new notification
      setNotifications(prevNotifications => [...prevNotifications, newNotification]);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleNotificationClick_1 = (event) => {
    setAnchorEl_1(event.currentTarget);
    setIsNotificationClicked(true);
  };

  const handleMenuClose_1 = () => {
    setAnchorEl_1(null);
    setIsNotificationClicked(false);

  };

  const isMenuOpen_1 = Boolean(anchorEl_1);
  const [totalTimer, setTotalTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const totalSeconds=JSON.parse(sessionStorage.getItem('availed-time'))
  const hours = Math.floor(totalSeconds / 3600) || 0;
  const minutes = Math.floor((totalSeconds % 3600) / 60) || 0;
  const seconds = totalSeconds % 60 || 0;
 
  const { localTimer, t } = useTimer();
   useEffect(() => {
      setTotalTime({hours,minutes,seconds})
  }, []);
  const list = (
    
    <Box
      sx={{ width: 250 ,marginLeft:"5px"}}
      role="presentation"
      onClick={toggleSideMenu}
      onKeyDown={toggleSideMenu}
    >
      <Box className="inside-menu">
      <List>
      <ListItem
        button
        className="listItem"
        style={{
          backgroundColor: activeTab === '/home' || hoveredTab === '/home' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
        onClick={() => navigate('/home')}
        onMouseEnter={() => handleMouseEnter('/home')}
        onMouseLeave={handleMouseLeave}
      >
        <ListItemIcon>
          {activeTab === '/home' || hoveredTab === '/home' ? (
            <ChatIcon className="icon" style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
          ) : (
            <ChatOutlinedIcon className="icon" style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
          )}
        </ListItemIcon>
        <ListItemText primary="Chat" />
      </ListItem>

      <ListItem
        button
        className="listItem"
        style={{
          backgroundColor: activeTab === '/assessment' || hoveredTab === '/assessment' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
        onClick={() => handleNavigation('/assessment')}
        onMouseEnter={() => handleMouseEnter('/assessment')}
        onMouseLeave={handleMouseLeave}
      >
        <ListItemIcon>
          {activeTab === '/assessment' || hoveredTab === '/assessment' ? (
            <AssignmentSharpIcon className="icon" style={{ marginRight: '5px', color: 'rgb(0, 0, 0)' }} />
          ) : (
            <AssignmentOutlinedIcon className="icon" style={{ marginRight: '5px', color: 'rgb(0, 0, 0)' }} />
          )}
        </ListItemIcon>
        <ListItemText primary="Take Assessment" />
        {/* {console.log(assessment_active,assessment_sub)} */}
        {!(assessment_active ||assessment_sub) && <LockIcon style={{ marginLeft: '-50px',marginBottom:"5px", color: 'rgb(70, 51, 105)' }} />}

      </ListItem>

      <ListItem
        button
        className="listItem"
        style={{
          backgroundColor: activeTab === '/analysis' || hoveredTab === '/analysis' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
        onClick={() => handleNavigation('/analysis')}
        onMouseEnter={() => handleMouseEnter('/analysis')}
        onMouseLeave={handleMouseLeave}
      >
        <ListItemIcon>
          {activeTab === '/analysis' || hoveredTab === '/analysis' ? (
            <InsertChartSharpIcon className="icon" style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
          ) : (
            <AssessmentOutlinedIcon className="icon" style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
          )}
        </ListItemIcon>
        <ListItemText primary="Analysis Dashboard" />
        {!(analysis_active || analysis_sub) && <LockIcon style={{ marginLeft: '-50px',marginBottom:"5px", color: 'rgb(70, 51, 105)' }} />}
      </ListItem>

      <ListItem
        button
        className="listItem"
        style={{
          backgroundColor: activeTab === '/profile' || hoveredTab === '/profile' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
        onClick={() => navigate('/profile')}
        onMouseEnter={() => handleMouseEnter('/profile')}
        onMouseLeave={handleMouseLeave}
      >
        <ListItemIcon>
          {activeTab === '/profile' || hoveredTab === '/profile' ? (
            <PersonIcon className="icon" style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
          ) : (
            <PersonOutlineOutlinedIcon className="icon" style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
          )}
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </ListItem>

      <ListItem
        button
        className="listItem"
        style={{
          backgroundColor: activeTab === '/feedback' || hoveredTab === '/feedback' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
        onClick={() => navigate('/feedback')}
        onMouseEnter={() => handleMouseEnter('/feedback')}
        onMouseLeave={handleMouseLeave}
      >
        <ListItemIcon>
          {activeTab === '/feedback' || hoveredTab === '/feedback' ? (
            <FeedbackIcon className="icon" style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
          ) : (
            <FeedbackOutlinedIcon className="icon" style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
          )}
        </ListItemIcon>
        <ListItemText primary="Feedback" />
      </ListItem>

      <ListItem
        button
        className="listItem"
        style={{
          backgroundColor: activeTab === '/subscription' || hoveredTab === '/subscription' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
        onClick={() => navigate('/subscription')}
        onMouseEnter={() => handleMouseEnter('/subscription')}
        onMouseLeave={handleMouseLeave}
      >
        <ListItemIcon>
          {activeTab === '/subscription' || hoveredTab === '/subscription' ? (
            <LoyaltyIcon className="icon" style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
          ) : (
            <LoyaltyOutlinedIcon className="icon" style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
          )}
        </ListItemIcon>
        <ListItemText primary="My Subscription" />
      </ListItem>

      <ListItem
        button
        className="listItem"
        style={{
          backgroundColor: activeTab === '/setting' || hoveredTab === '/setting' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
        onClick={() => navigate('/setting')}
        onMouseEnter={() => handleMouseEnter('/setting')}
        onMouseLeave={handleMouseLeave}
      >
        <ListItemIcon>
          {activeTab === '/setting' || hoveredTab === '/setting' ? (
            <SettingsIcon className="icon" style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
          ) : (
            <SettingsOutlinedIcon className="icon" style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
          )}
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>

      <ListItem
        button
        className="listItem"
        style={{
          backgroundColor: activeTab === '/manage-account' || hoveredTab === '/manage-account' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
        onClick={() => navigate('/manage-account')}
        onMouseEnter={() => handleMouseEnter('/manage-account')}
        onMouseLeave={handleMouseLeave}
      >
        <ListItemIcon>
          {activeTab === '/manage-account' || hoveredTab === '/manage-account' ? (
            <ManageAccountsIcon className="icon" style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
          ) : (
            <ManageAccountsOutlinedIcon className="icon" style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
          )}
        </ListItemIcon>
        <ListItemText primary="Manage Account" />
      </ListItem>


      <ListItem
        button
        className="listItem"
        onClick={handleLogout}
        onMouseEnter={() => handleMouseEnter('/logout')}
        onMouseLeave={handleMouseLeave}
      >
        <ListItemIcon>
          <LogoutIcon className="icon" style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
        </ListItemIcon>
        <ListItemText primary="LogOut" />
      </ListItem>
      </List>
      </Box>
      <div className="timer-updated">
      <WatchLaterIcon className="timerIcon" />

        <div className="Session-time">Session Time: {`${localTimer.hours || 0}h:${localTimer.minutes || 0}m:${localTimer.seconds || 0}s`}</div>
       
        <div className="Total-time">Total Time: {`${totalTimer.hours || 0}h:${totalTimer.minutes || 0}m:${totalTimer.seconds|| 0}s`}</div>
      </div>
      <div className="profile-pic-side">
        <Box sx={{ flexGrow: 0 }}>

          <Box className="profile-down">
          <Avatar
            alt="Profile Picture"
            src={profilePic}
            className="profile-pic"
          />
          </Box>
        </Box>
      </div>

    </Box>
  );
 

  const handleClick = () => {
    const chatType = localStorage.getItem('chatType');
    window.open('https://www.baia.ai', '_blank'); // Open URL in a new tab
  }
  
  return (
    <div className="nav">
       <SubscriptionModal open={isModalOpen} onClose={() => setModalOpen(false)} />
    <div className="side-menu">
        <IconButton
          aria-label="Side Menu"
          onClick={toggleSideMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
      </div>
      {/* <div className="baia">
        <img className="baiaIcon" loading="lazy" alt="" src="/baia-1@2x.png" />
      </div> */}
      <div className="baia" onClick={handleClick}>
      <img className="baiaIcon" loading="lazy" alt="Baia Icon" src="/baia-1@2x.png" />
    </div>

  <div className="chat-parent">
      <div
        className="sub"
        onClick={() => navigate('/home')}
        onMouseEnter={handleMouseEnterHome}
        onMouseLeave={handleMouseLeaveHome}
        style={{
          padding: '10px',
          borderRadius: '5px',
          backgroundColor: activeTab === '/home' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
      >
        {(activeTab === '/home' || hoveredHome) ? (
          <ChatIcon style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
        ) : (
          <ChatOutlinedIcon style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
        )}
        <div className="chat">
          <div className="chat1">Chat</div>
        </div>
      </div>

      <div
        className={`sub ${!(assessment_active || assessment_sub) ? 'restricted' : ''}`}
        onClick={() => handleNavigation('/assessment')}
        onMouseEnter={handleMouseEnterAssessment}
        onMouseLeave={handleMouseLeaveAssessment}
        style={{
          padding: '10px',
          borderRadius: '5px',
          backgroundColor: activeTab === '/assessment' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
      >
        {(activeTab === '/assessment' || hoveredAssessment) ? (
          <AssignmentSharpIcon style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
        ) : (
          <AssignmentOutlinedIcon style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
        )}
        <div className="assessment">
          
          <div className="take-assisment">
          Take Assessment 
          </div>
        </div>
         {!(assessment_active || assessment_sub) && <LockIcon style={{ marginLeft: '-50px',marginBottom:"5px", color: 'rgb(70, 51, 105)' }} />}
      </div>

      <div
        className={`sub ${!(analysis_active || analysis_sub) ? 'restricted' : ''}`}
        onClick={() => handleNavigation('/analysis')}
        onMouseEnter={handleMouseEnterAnalysis}
        onMouseLeave={handleMouseLeaveAnalysis}
        style={{
          padding: '10px',
          borderRadius: '5px',
          
          backgroundColor: activeTab === '/analysis' ? 'rgb(70, 3, 90,0.2)' : 'transparent',
        }}
      >
        {(activeTab === '/analysis' || hoveredAnalysis) ? (
          <InsertChartSharpIcon style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
        ) : (
          <AssessmentOutlinedIcon style={{ marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
        )}
        <div className="analysis">
          <div className="analysis-dashboard">
            Analysis Dashboard 
          </div>
        </div>
        {!(analysis_active || analysis_sub) && <LockIcon style={{ marginLeft: '-50px',marginBottom:"5px", color: 'rgb(70, 51, 105)' }} />}
      </div>
    </div>
     
    <div className="notification-icon">
  <IconButton
    color="inherit"
    onClick={handleNotificationClick_1}
    onMouseEnter={handleMouseEnterNotification}
    onMouseLeave={handleMouseLeaveNotification}
  >
    <Badge
      badgeContent={notifications.length}
      color="error"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      overlap="circular"
    >
      {isNotificationHovered || isNotificationClicked ? (
        <NotificationsIcon sx={{marginRight: '5px', color: 'rgb(70, 51, 105)' }}/>
      ) : (
        <NotificationsNoneIcon sx={{marginRight: '5px', color: 'rgb(70, 51, 105)' }} />
      )}
    </Badge>
  </IconButton>
  <Menu
    anchorEl={anchorEl_1}
    keepMounted
    open={Boolean(anchorEl_1)}
    onClose={handleMenuClose_1}
    sx={{
      mt: 1,
      '& .MuiPaper-root': {
        borderRadius: '5px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
        padding: '5px',
        width: '300px' // Set the width of the menu
      }
    }}
  >
    {/* Render notifications grouped by message_type */}
    {Object.entries(
      notifications.reduce((acc, notification) => {
        const { type, message } = notification;
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push(message);
        return acc;
      }, {})
    ).map(([messageType, messages]) => (
      <div key={messageType}>
        <Typography variant="subtitle1" className="notification-header">
          {messageType} Notifications
        </Typography>
        <List sx={{ width: '100%' }}>
          {messages.map((message, index) => (
            <ListItem key={index} button>
              <ListItemText
                primary={message}
                primaryTypographyProps={{ noWrap: false }}
                sx={{ whiteSpace: 'pre-line' }}
              />
            </ListItem>
          ))}
        </List>
      </div>
    ))}
  </Menu>
</div>

      <div className="profile-pic-wrapper">
        {/* Profile picture */}
        <Box sx={{ flexGrow: 0 }}>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <Avatar
              alt="Profile Picture"
              src={profilePic}
              className="profile-pic"
            />
          </IconButton>
          {renderMenu}
        </Box>
      </div>
      <Drawer
        anchor="left"
        open={isSideMenuOpen}
        onClose={toggleSideMenu}
      >
        {list}
      </Drawer>
    </div>
  

  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  // PreviousChat: PropTypes.func.isRequired,
  // FreshChat: PropTypes.func.isRequired,
};

export default NavBar;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* .image-placeholder {
  position: absolute;
  height: 78.28%;
  width: 86.37%;
  top: 0;
  right: 20.63%;
  bottom: 24.72%;
  left: 0;
  border-radius: 100px 0 0 0;
  background-color: var(--color-tan);

} */
/* .active-indicator {
  position: absolute;
  height: 70.15%;
  width: 80.04%;
  top: 26.85%;
  right: -2.26%;
  bottom: 0;
  left: 18.22%;
  border-radius: 0 0 100px 0;
  background-color: var(--color-darkslateblue-400);
  z-index: 1;
} */
.inactive-indicator-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 100px 0px 100px 5px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;

}

/* Mobile View Adjustments */
@media (max-width: 1030px) {
  

  .image {
    display:none;
   
  }


  
}`, "",{"version":3,"sources":["webpack://./src/Landing_page/Image1.css"],"names":[],"mappings":";;AAEA;;;;;;;;;;;GAWG;AACH;;;;;;;;;;;GAWG;AACH;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,kCAAkC;EAClC,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;;AAErB;;AAEA,4BAA4B;AAC5B;;;EAGE;IACE,YAAY;;EAEd;;;;AAIF","sourcesContent":["\n\n/* .image-placeholder {\n  position: absolute;\n  height: 78.28%;\n  width: 86.37%;\n  top: 0;\n  right: 20.63%;\n  bottom: 24.72%;\n  left: 0;\n  border-radius: 100px 0 0 0;\n  background-color: var(--color-tan);\n\n} */\n/* .active-indicator {\n  position: absolute;\n  height: 70.15%;\n  width: 80.04%;\n  top: 26.85%;\n  right: -2.26%;\n  bottom: 0;\n  left: 18.22%;\n  border-radius: 0 0 100px 0;\n  background-color: var(--color-darkslateblue-400);\n  z-index: 1;\n} */\n.inactive-indicator-icon {\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  border-radius: 100px 0px 100px 5px;\n  max-width: 100%;\n  overflow: hidden;\n  max-height: 100%;\n  object-fit: contain;\n\n}\n\n/* Mobile View Adjustments */\n@media (max-width: 1030px) {\n  \n\n  .image {\n    display:none;\n   \n  }\n\n\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

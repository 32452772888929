import React from 'react';
import { Modal, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SubscriptionModal = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleSubscribe = () => {
    navigate('/subscription'); // Redirect to the subscription page
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="subscription-modal-title"
      aria-describedby="subscription-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
          borderRadius:"10px"
        }}
      >
        <Typography id="subscription-modal-title" variant="h7" component="h4">
        Your trial period has ended. Please subscribe to continue.
        </Typography>
       
        <Box sx={{ mt: 2 }}>
          <Button onClick={handleSubscribe} variant="contained" color="primary" sx={{ mr: 2,backgroundColor:"rgb(70, 3, 90)" ,':hover': { backgroundColor: 'rgb(70, 3, 90)' }}}>
            Subscribe
          </Button>
          <Button onClick={onClose} variant="outlined" color="secondary">
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SubscriptionModal;

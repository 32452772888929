

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import Sidebar from './Sidebar_main';
import './Header.css';
import img from '../images/baia_new.png';
import { useProfileImageContext } from '../context/ProfileImageContext';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BASE_URL } from '../config';
import { useNavigate } from 'react-router-dom';
import { getSessionData } from '../utils/session';
import { fetchPreviousChat, fetchFreshChat } from '../api';
import { CircularProgress } from '@mui/material';
import { useTimer } from '../context/TimerContext';
import { Link } from 'react-router-dom';



const Header = ({ className = '', PreviousChat, FreshChat }) => {
  const chatType = localStorage.getItem('chatType');
  const [freshChatActive, setFreshChatActive] = useState(false);
  const [previousChatActive, setPreviousChatActive] = useState(false);
  const { profileImage } = useProfileImageContext();
  const [anchorEl, setAnchorEl] = useState(null);
  // const [deleteDuration, setDeleteDuration] = useState('All chats');
  const [deleteDuration, setDeleteDuration] = useState('Current Session');
  const [days, setDays] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const user_name = userinfo.username;

  useEffect(() => {
    if (chatType === 'fresh') {
      handleFreshChatClick();
    } else {
      handlePreviousChatClick();
    }
  }, []);



  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const sessionData = getSessionData();

  const handleFetchFreshChat = async () => {
    try {
      const freshEndpoint = sessionStorage.getItem('freshEndpoint');
      if (!freshEndpoint) throw new Error('Fresh endpoint not found in session storage.');
      const freshChatMessages = await fetchFreshChat(user_name, freshEndpoint);
      return freshChatMessages;
    } catch (error) {
      console.error('Error fetching fresh chat messages:', error);
      return [];
    }
  };

  const handleFetchPreviousChat = async (days) => {
    try {
      const continuousEndpoint = sessionStorage.getItem('continuousEndpoint');
      if (!continuousEndpoint) throw new Error('Continuous endpoint not found in session storage.');
      const chatMessages = await fetchPreviousChat(user_name, days, continuousEndpoint);
      return chatMessages;
    } catch (error) {
      console.error('Error fetching previous chat messages:', error);
      return [];
    }
  };

  const fetchData_fresh = async () => {
    setIsLoading(true);
    try {
      const freshChatMessages = await handleFetchFreshChat();
      if (freshChatMessages) {
        sessionStorage.setItem('freshChat', JSON.stringify(freshChatMessages));
      }
    } catch (error) {
      console.error('Error in fetchData:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData_continuous = async (days) => {
    setIsLoading(true);
    try {
      const previousChatMessages = await handleFetchPreviousChat(days);
      if (previousChatMessages) {
        sessionStorage.setItem('previousChat', JSON.stringify(previousChatMessages));
      }
    } catch (error) {
      console.error('Error in fetchData:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const user = sessionData?.username;
  const handleDeleteChat = async () => {
    try {
      if (deleteDuration === 'current_chat') {
        await deleteSessionHistory(); // Call the session-specific delete function
      } else {
        const response = await fetch(`${BASE_URL}/api/delete_db_history`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_name: user,
            duration: deleteDuration,
          }),
        });
  
        const data = await response.json();
  
        if (data.status === 'Success.') {
          if (freshChatActive) {
            await fetchData_fresh();
            console.log('Chat history deleted successfully.');
            window.location.reload();
          } else {
            await fetchData_continuous(1); // Assuming fetchPreviousChat is async
            console.log('Chat history deleted successfully.');
            window.location.reload();
          }
        } else {
          console.error('Failed to delete chat history:', data.error);
        }
      }
      handleMenuClose();
    } catch (error) {
      console.error('Error deleting chat history:', error);
      handleMenuClose();
    }
  };
  // const handleDeleteChat = async () => {
  //   try {
  //     const response = await fetch(`${BASE_URL}/api/delete_db_history`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         user_name: user,
  //         duration: deleteDuration,
  //       }),
  //     });

  //     const data = await response.json();

  //     if (data.status === 'Success.') {
  //       if (freshChatActive) {
  //         await fetchData_fresh();
  //         console.log('Chat history deleted successfully.');
  //         window.location.reload();
          
  //       } else {
  //         await fetchData_continuous(1); // Assuming fetchPreviousChat is async
  //         console.log('Chat history deleted successfully.');
  //         window.location.reload();
  //       }
  //     } else {
  //       console.error('Failed to delete chat history:', data.error);
  //     }
  //     handleMenuClose();
  //   } catch (error) {
  //     console.error('Error deleting chat history:', error);
  //     handleMenuClose();
  //   }
  // };

  const getSessionId = (chatType) => {
    const sessionId = sessionStorage.getItem('sessionId');
    return `${sessionId}-${chatType}`;
  };


  const deleteSessionHistory = async () => {
    try {
     const chatType=localStorage.getItem('chatType')
     console.log(chatType)
      const sessionId = getSessionId(chatType);
      console.log(sessionId)
      const response = await fetch(`${BASE_URL}/api/delete_db_history_session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_name: user_name,
          session_id: sessionId,
          duration: 1,
        }),
      });
  
      const data = await response.json();
  
      if (data.status === 'Success.') {
        console.log('Session history deleted successfully.');
        if (freshChatActive) {
          await fetchData_fresh();
          console.log('Chat history deleted successfully.');
          window.location.reload();
          
        } else {
          await fetchData_continuous(1); // Assuming fetchPreviousChat is async
          console.log('Chat history deleted successfully.');
          window.location.reload();
        }
        // console.log("session history deleted ")
      } else {
        console.error('Failed to delete session history:', data.error);
      }
    } catch (error) {
      console.error('Error deleting session history:', error);
    }
  };
  
  const handleDurationChange = (event) => {
    setDeleteDuration(event.target.value);
  };

  const handleDaysChange = async (event) => {
    const selectedDays = event.target.value;
    setDays(selectedDays);
    localStorage.setItem('days', selectedDays);
    await fetchData_continuous(selectedDays);
    PreviousChat()
  };

  const handleFreshChatClick = () => {
    FreshChat();
    setFreshChatActive(true);
    setPreviousChatActive(false);
  };

  const handlePreviousChatClick = () => {
    PreviousChat();
    setPreviousChatActive(true);
    setFreshChatActive(false);
  };

  // const handleDeleteButtonClick = (event) => {
  //   if (freshChatActive) {
  //     setDeleteDuration('1');
  //     handleDeleteChat();
  //   } else {
  //     handleMenuOpen(event);
  //   }
  // };

  const handleDeleteButtonClick = (event) => {
    if (freshChatActive) {
      console.log("inside")
      localStorage.setItem('chatType','fresh')
      deleteSessionHistory();
    } else {
      handleMenuOpen(event);
    }
  };


  const handleProfileClick = () => {
    navigate('/setting');
  };
  return (
    <div className={`header1 ${className}`}>
      {isLoading && (
        <div className="loading-screen" >
       <div className="loading-circle-home"></div>
       </div>
      )}
      <div className="header-separator" />
      {/* {profileImage ? (
        <img src={profileImage} alt="Profile" className="baia-profile" />
      ) : (
        <img src={img} alt="Profile" className="baia-profile"  />
      )} */}

        {profileImage ? (
          <img src={profileImage} onClick={handleProfileClick} alt="Profile" className="baia-profile" />
        ) : (
          <img src={img} onClick={handleProfileClick} alt="Profile" className="baia-profile" />
        )}
      {/* Chat Buttons */}
      <div >
        <div className={`fresh-chat-button ${freshChatActive ? 'active' : ''}`} onClick={handleFreshChatClick}>
          {/* <div className="fresh-background" /> */}
          <a className="fresh-chat">New session</a>
        </div>
      </div>
      <div className='prev'>
        <div >
          <div className={`previous-chat-button ${previousChatActive ? 'active' : ''}`} onClick={handlePreviousChatClick}>
            {/* <div className="previous-backgrounds" /> */}
            <a className="prevoius-chat">Previous session</a>
          </div>
        </div>

        {/* Select Days Dropdown */}
        {previousChatActive && (
          <div className="days-container">
            <select id="days-select" value={days} onChange={handleDaysChange}>
              <option value="1">1 day</option>
              <option value="2">2 days</option>
              <option value="3">3 days</option>
            </select>
          </div>
        )}
      </div>
     
      <div className="delete-chat-container">
        <button className="delete-chat-btn" onClick={handleDeleteButtonClick}>
          Delete Chat
        </button>
        {previousChatActive && (
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            sx={{
              mt: 6,
              '& .MuiPaper-root': {
                backgroundColor: '#fff',
                borderRadius: '5px',
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                padding: '0px 20px 0px 10px',
              },
            }}
          >
            {/* <MenuItem>
              <select value={deleteDuration} onChange={handleDurationChange}>
                <option value="All chats">All chats</option>
                <option value="1">1 day</option>
                <option value="2">2 days</option>
                <option value="7">1 week</option>
                <option value="30">1 month</option>
              </select>
            </MenuItem> */}
            <MenuItem>
  <select value={deleteDuration} onChange={handleDurationChange}>
  <option value="current_chat">Current Session</option> {/* New Option */}
    <option value="1">1 day</option>
    <option value="2">2 days</option>
    <option value="7">1 week</option>
    <option value="30">1 month</option>
    <option value="All chats">All chats</option>
    
  </select>
</MenuItem>
            <MenuItem onClick={handleDeleteChat}>Submit</MenuItem>
          </Menu>
        )}
      </div>

     
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  PreviousChat: PropTypes.func.isRequired,
  FreshChat: PropTypes.func.isRequired,
};

export default Header;

// import React, { useState, useEffect } from 'react';
// import { Grid,Box, Typography, FormControlLabel, Checkbox, Button,Paper } from '@mui/material';
// import { updateVoiceConcent } from '../api';
// import NavBar from '../HomePage/NavBar_home';
// import './ManageAccount.css'
// const ManageAccount = () => {
//   const [hasVoiceConsent, setHasVoiceConsent] = useState(false);
//   const [voiceConsentChecked, setVoiceConsentChecked] = useState(false);
//   const [editMode, setEditMode] = useState(false);

//   useEffect(() => {
//     const consent = JSON.parse(sessionStorage.getItem('consent'));
//     if (consent) {
//       setHasVoiceConsent(consent);
//       setVoiceConsentChecked(consent);
//     }
//   }, []);

//   const handleCheckboxChange = (event) => {
//     setVoiceConsentChecked(event.target.checked);
//   };

//   const handleEditClick = () => {
//     setEditMode(true);
//   };

//   const handleCancelClick = () => {
//     setEditMode(false);
//     setVoiceConsentChecked(hasVoiceConsent);
//   };

//   const handleVoiceConsentSubmit = async () => {
//     const userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
//     const user_name = userinfo.username;

//     try {
//       const response = await updateVoiceConcent(user_name, voiceConsentChecked);
//       if (response) {
//         setHasVoiceConsent(voiceConsentChecked);
//         setEditMode(false);
//         sessionStorage.setItem('consent', JSON.stringify(voiceConsentChecked));
//       }
//     } catch (error) {
//       console.error('Error updating voice consent:', error);
//     }
//   };

//   return (

//         <Box className="manage-account">
//           <NavBar />
    
//           {!hasVoiceConsent ? (
//             <Paper elevation={3} className="consent-accepted">
//               <Typography variant="h6" gutterBottom>
//                 Voice Consent
//               </Typography>
//               <Typography variant="body1" gutterBottom>
//                 By enabling this feature, you agree to let us use your voice data for a better experience.
//               </Typography>
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={voiceConsentChecked}
//                     onChange={handleCheckboxChange}
//                   />
//                 }
//                 label={
//                   <Typography variant="body2">
//                     I agree to provide voice consent and I have also read all the{' '}
//                     <a href="https://www.baia.ai/terms-and-condition/" target="_blank" rel="noopener noreferrer">
//                       Terms
//                     </a>,{' '}
//                     <a href="https://www.baia.ai/disclaimer/" target="_blank" rel="noopener noreferrer">
//                       Disclaimer
//                     </a>, and{' '}
//                     <a href="https://www.baia.ai/privacy-policy/" target="_blank" rel="noopener noreferrer">
//                       Privacy Policy
//                     </a>.
//                   </Typography>
//                 }
//               />
//               <Grid container spacing={2} className="button-container">
//                 <Grid item>
//                   <Button variant="contained" color="primary" onClick={handleVoiceConsentSubmit} sx={{bgcolor:'rgb(70, 3, 90)'}}>
//                     Submit
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Paper>
//           ) : (
//             <Paper elevation={3} className="consent-accepted">
//               <Typography variant="body1" gutterBottom>
//                 Voice consent has been accepted.
//               </Typography>
//               {!editMode ? (
//                 <Grid container spacing={2} className="button-container">
//                   <Grid item>
//                     <Button variant="outlined" color="primary" onClick={handleEditClick} 
//                      sx={{bgcolor:'rgb(70, 3, 90)',color:'white',
//                      '&:hover': {
//                         backgroundColor: 'rgb(70,3,90)',
//                         borderColor: 'rgb(58, 6, 107)',
//                       },}}>
//                       Edit Consent
//                     </Button>
//                   </Grid>
//                 </Grid>
//               ) : (
//                 <Box>
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         checked={voiceConsentChecked}
//                         onChange={handleCheckboxChange}
//                         // sx={{bgcolor:'rgb(70, 3, 90)'}}
//                       />
//                     }
//                     label={
//                       <Typography variant="body2">
//                         I agree to provide voice consent and I have also read all the{' '}
//                         <a href="https://www.baia.ai/terms-and-condition/" target="_blank" rel="noopener noreferrer">
//                           Terms
//                         </a>,{' '}
//                         <a href="https://www.baia.ai/disclaimer/" target="_blank" rel="noopener noreferrer">
//                           Disclaimer
//                         </a>, and{' '}
//                         <a href="https://www.baia.ai/privacy-policy/" target="_blank" rel="noopener noreferrer">
//                           Privacy Policy
//                         </a>.
//                       </Typography>
//                     }
//                   />
//                   <Grid container spacing={2} className="button-container">
//                     <Grid item>
//                       <Button variant="contained" color="primary" onClick={handleVoiceConsentSubmit}  
                      
//                       sx={{bgcolor:'rgb(70, 3, 90)',
//                       '&:hover': {
//                         backgroundColor: 'rgb(70,3,90)',
//                         borderColor: 'rgb(58, 6, 107)',
//                       },
//                       }}>
//                         Submit
//                       </Button>
//                     </Grid>
//                     <Grid item>
//                       <Button variant="text" color="secondary" onClick={handleCancelClick}  sx={{border:'1px solid rgb(70, 3, 90)'}}>
//                         Cancel
//                       </Button>
//                     </Grid>
//                   </Grid>
//                 </Box>
//               )}
//             </Paper>
//           )}
//         </Box>
//   )
// };

// export default ManageAccount;

// import React, { useState, useEffect } from 'react';
// import { Grid, Box, Typography, FormControlLabel, Checkbox, Button, Paper } from '@mui/material';
// import { updateVoiceConcent } from '../api';
// import NavBar from '../HomePage/NavBar_home';
// import './ManageAccount.css';

// const ManageAccount = () => {
//   const [hasVoiceConsent, setHasVoiceConsent] = useState(false);
//   const [voiceConsentChecked, setVoiceConsentChecked] = useState(false);
//   const [disagreeChecked, setDisagreeChecked] = useState(false);
//   const [editMode, setEditMode] = useState(false);

//   useEffect(() => {
//     const consent = JSON.parse(sessionStorage.getItem('consent'));
//     if (consent !== null) {
//       setHasVoiceConsent(consent);
//       setVoiceConsentChecked(consent);
//       setDisagreeChecked(!consent);
//     }
//   }, []);

//   const handleAgreeCheckboxChange = (event) => {
//     setVoiceConsentChecked(event.target.checked);
//     if (event.target.checked) {
//       setDisagreeChecked(false);
//     }
//   };

//   const handleDisagreeCheckboxChange = (event) => {
//     setDisagreeChecked(event.target.checked);
//     if (event.target.checked) {
//       setVoiceConsentChecked(false);
//     }
//   };

//   const handleEditClick = () => {
//     setEditMode(true);
//   };

//   const handleCancelClick = () => {
//     setEditMode(false);
//     setVoiceConsentChecked(hasVoiceConsent);
//     setDisagreeChecked(!hasVoiceConsent);
//   };

//   const handleVoiceConsentSubmit = async () => {
//     const userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
//     const user_name = userinfo.username;

//     try {
//       const consentValue = voiceConsentChecked && !disagreeChecked;
//       const response = await updateVoiceConcent(user_name, consentValue);
//       if (response) {
//         setHasVoiceConsent(consentValue);
//         setEditMode(false);
//         sessionStorage.setItem('consent', JSON.stringify(consentValue));
//       }
//     } catch (error) {
//       console.error('Error updating voice consent:', error);
//     }
//   };

//   return (
//     <Box className="manage-account">
//       <NavBar />

//       {!editMode ? (
//         <Paper elevation={3} className="consent-accepted">
//           <Typography variant="h6" gutterBottom>
//             Voice Consent
//           </Typography>
//           <Typography variant="body1" gutterBottom>
//             {hasVoiceConsent ? "You have agreed to provide voice consent." : "You have disagreed to provide voice consent."}
//           </Typography>
//           <Grid container spacing={2} className="button-container">
//             <Grid item>
//               <Button variant="outlined" color="primary" onClick={handleEditClick}
//                 sx={{
//                   bgcolor: 'rgb(70, 3, 90)', color: 'white',
//                   '&:hover': {
//                     backgroundColor: 'rgb(70,3,90)',
//                     borderColor: 'rgb(58, 6, 107)',
//                   },
//                 }}>
//                 Edit Consent
//               </Button>
//             </Grid>
//           </Grid>
//         </Paper>
//       ) : (
//         <Paper elevation={3} className="consent-accepted">
//           <Typography variant="h6" gutterBottom>
//             Edit Voice Consent
//           </Typography>
//           <Grid container direction="column" spacing={2}>
//             <Grid item>
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={voiceConsentChecked}
//                     onChange={handleAgreeCheckboxChange}
//                   />
//                 }
//                 label={
//                   <Typography variant="body2">
//                     I agree to provide voice consent and I have also read all the{' '}
//                     <a href="https://www.baia.ai/terms-and-condition/" target="_blank" rel="noopener noreferrer">
//                       Terms
//                     </a>,{' '}
//                     <a href="https://www.baia.ai/disclaimer/" target="_blank" rel="noopener noreferrer">
//                       Disclaimer
//                     </a>, and{' '}
//                     <a href="https://www.baia.ai/privacy-policy/" target="_blank" rel="noopener noreferrer">
//                       Privacy Policy
//                     </a>.
//                   </Typography>
//                 }
//               />
//             </Grid>
//             <Grid item>
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={disagreeChecked}
//                     onChange={handleDisagreeCheckboxChange}
//                   />
//                 }
//                 label={
//                   <Typography variant="body2">
//                     I disagree to submit the voice consent.
//                   </Typography>
//                 }
//               />
//             </Grid>
//           </Grid>
//           <Grid container spacing={2} className="button-container">
//             <Grid item>
//               <Button variant="contained" color="primary" onClick={handleVoiceConsentSubmit}
//                 sx={{
//                   bgcolor: 'rgb(70, 3, 90)',
//                   '&:hover': {
//                     backgroundColor: 'rgb(70,3,90)',
//                     borderColor: 'rgb(58, 6, 107)',
//                   },
//                 }}>
//                 Submit
//               </Button>
//             </Grid>
//             <Grid item>
//               <Button variant="text" color="secondary" onClick={handleCancelClick} sx={{ border: '1px solid rgb(70, 3, 90)' }}>
//                 Cancel
//               </Button>
//             </Grid>
//           </Grid>
//         </Paper>
//       )}
//     </Box>
//   );
// };

// export default ManageAccount;


import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, FormControlLabel, Checkbox, Button, Paper } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock'; // Import LockIcon
import { updateVoiceConcent } from '../api';
import NavBar from '../HomePage/NavBar_home';
import './ManageAccount.css';

const ManageAccount = () => {
  const [hasVoiceConsent, setHasVoiceConsent] = useState(false);
  const [voiceConsentChecked, setVoiceConsentChecked] = useState(false);
  const [disagreeChecked, setDisagreeChecked] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isProOrAdvanced, setIsProOrAdvanced] = useState(false);

  useEffect(() => {
    const consent = JSON.parse(sessionStorage.getItem('consent'));
    const userInfo = JSON.parse(sessionStorage.getItem('central_subscription'));
    const productName = userInfo?.product_name;
    const initial_sub=sessionStorage.getItem('initial_subscription')
    const initial_details = JSON.parse(sessionStorage.getItem('initial_subscription'));
    const initialcasualChatStatus = initial_details.enable_casual_chat_session_status;
    if (consent !== null) {
      setHasVoiceConsent(consent);
      setVoiceConsentChecked(consent);
      setDisagreeChecked(!consent);
    }

    // Check if the product name is "Advanced" or "Pro"
    if (productName === 'ADVACED' || productName === 'PRO' || initialcasualChatStatus) {
      setIsProOrAdvanced(true);
    }
  }, []);

  const handleAgreeCheckboxChange = (event) => {
    setVoiceConsentChecked(event.target.checked);
    if (event.target.checked) {
      setDisagreeChecked(false);
    }
  };

  const handleDisagreeCheckboxChange = (event) => {
    setDisagreeChecked(event.target.checked);
    if (event.target.checked) {
      setVoiceConsentChecked(false);
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setVoiceConsentChecked(hasVoiceConsent);
    setDisagreeChecked(!hasVoiceConsent);
  };

  const handleVoiceConsentSubmit = async () => {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    const userName = userInfo.username;

    try {
      const consentValue = voiceConsentChecked && !disagreeChecked;
      const response = await updateVoiceConcent(userName, consentValue);
      if (response) {
        setHasVoiceConsent(consentValue);
        setEditMode(false);
        sessionStorage.setItem('consent', JSON.stringify(consentValue));
      }
    } catch (error) {
      console.error('Error updating voice consent:', error);
    }
  };

  return (
    <Box className="manage-account">
    <NavBar />
    {!isProOrAdvanced ? (
      <Paper elevation={3} className="consent-disabled">
        <Box className="disabled-content">
          <LockIcon color="error" />
          <Typography variant="h6" gutterBottom>
            Voice Consent
          </Typography>
          <Typography variant="body1" gutterBottom>
            This feature is only available for Pro and Advanced users.
          </Typography>
        </Box>
      </Paper>
    ) : !editMode ? (
      <Paper elevation={3} className="consent-accepted">
        <Typography variant="h6" gutterBottom>
          Voice Consent
        </Typography>
        <Typography variant="body1" gutterBottom>
          {hasVoiceConsent
            ? "Since you have accepted the consent for the voice service, this feature will be available to you."
            : "Since you have not accepted the consent for the voice service, this feature will not be available to you."
          }
        </Typography>
        <Grid container spacing={2} className="button-container">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleEditClick}
              sx={{
                bgcolor: 'rgb(70, 3, 90)',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgb(70,3,90)',
                  borderColor: 'rgb(58, 6, 107)',
                },
              }}
            >
              Edit Consent
            </Button>
          </Grid>
        </Grid>
      </Paper>
    ) : (
      <Paper elevation={3} className="consent-edit">
        <Typography variant="h6" gutterBottom>
          Edit Voice Consent
        </Typography>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={voiceConsentChecked}
                  onChange={handleAgreeCheckboxChange}
                />
              }
              label={
                <Typography variant="body2">
                  I agree to give my voice consent with the understanding that it may involve the transfer of data to third parties. I have also read and understood the
                  {' '}
                  <a href="https://www.baia.ai/terms-and-condition/" target="_blank" rel="noopener noreferrer">
                    Terms
                  </a>,{' '}
                  <a href="https://www.baia.ai/disclaimer/" target="_blank" rel="noopener noreferrer">
                    Disclaimer
                  </a>, and{' '}
                  <a href="https://www.baia.ai/privacy-policy/" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>.
                </Typography>
              }
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={disagreeChecked}
                  onChange={handleDisagreeCheckboxChange}
                />
              }
              label={
                <Typography variant="body2">
                  I disagree to submit the voice consent.
                </Typography>
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="button-container">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleVoiceConsentSubmit}
              sx={{
                bgcolor: 'rgb(70, 3, 90)',
                '&:hover': {
                  backgroundColor: 'rgb(70,3,90)',
                  borderColor: 'rgb(58, 6, 107)',
                },
              }}
            >
              Submit
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="text"
              color="secondary"
              onClick={handleCancelClick}
              sx={{ border: '1px solid rgb(70, 3, 90)', color: 'rgb(70, 3, 90)' }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Paper>
    )}
  </Box>

  );
};

export default ManageAccount;

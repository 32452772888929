import React from 'react';
import { Grid, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const ProfileField = ({ label, value }) => (
  <Grid item xs={12} sm={5} sx={{
    backgroundColor: "rgba(188, 175, 223, 0.829)",
    borderRadius: "5px",
    marginBottom: '10px',
    marginRight: "10px",
    padding: '5px',
    fontFamily:'Roboto'
  }}>
    <div className="profileField">
      <FiberManualRecordIcon sx={{ fontSize: 12, marginRight: 3, }} />
      <strong>{label}:</strong>
      <Typography component="span" sx={{ marginLeft: '8px',fontFamily:'Roboto' }}>
        {value}
      </Typography>
    </div>
  </Grid>
);

export default ProfileField;

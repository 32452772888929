
// src/utils/session.js
import { BASE_URL } from "../config";


export const saveSessionData = (data) => {
  sessionStorage.setItem('userInfo', JSON.stringify(data));
};

export const getSessionData = () => {
  const data = sessionStorage.getItem('userInfo');
  return data ? JSON.parse(data) : null;
};

export const clearSessionData = () => {
  sessionStorage.removeItem('userInfo');
  sessionStorage.removeItem('chatMessages');
  sessionStorage.removeItem('freshChat');
  sessionStorage.removeItem('previousChat');
  sessionStorage.removeItem('central_subscription');
  sessionStorage.removeItem('intial_subscription')
  sessionStorage.removeItem('loggedIn');
  sessionStorage.removeItem('central_detail')
  sessionStorage.removeItem('consent')
};

import React from 'react';

const StripePricingTable = () => {
  const iframeSrc = `
    <iframe
      width="100%"
      height="2000"
      frameborder="0"
      scrolling="Yes"
      srcdoc='<script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      <stripe-pricing-table pricing-table-id="prctbl_1PgR4yGujhqSRT3LuLW1ATOA"
      publishable-key="pk_live_51Or05DGujhqSRT3LCqMr2FWv9Le9g0Ia57xnGLHnTyZzpXaNVy8qRG2vJeLiZu3rXpIKGZzJdFjFQajbwrDpapZP00WL4Pk9q5">
      </stripe-pricing-table>'
      allowfullscreen
    ></iframe>
  `;

  const termsPolicyRedirectText = (
    'To handle your payment and subscription, kindly <a href="https://billing.stripe.com/p/login/bIYg0d1ut5m4gsocMM" target="_blank">click here</a> and log in using the official email address you used to register in BAIA.'
  );

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: iframeSrc }} />
      <div dangerouslySetInnerHTML={{ __html: termsPolicyRedirectText }} />
    </div>
  );
};

export default StripePricingTable;

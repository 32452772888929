import { useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext';

const useAutoLogoff = (timeout = 5 * 1000) => { // 1 second for testing
  const navigate = useNavigate();
  const timerRef = useRef(null);
  const { handleLogout } = useContext(AuthContext);

  const logoffUser = () => {
    console.log('Logging out due to inactivity...');
    handleLogout();
    navigate('/');
  };

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(logoffUser, timeout);
    // console.log(`Timer reset for ${timeout / 1000} seconds`);
  };

  useEffect(() => {
    const handleActivity = () => {
    //   console.log('User activity detected. Resetting timer...');
      resetTimer();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('mousedown', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('touchstart', handleActivity);

    resetTimer();

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('mousedown', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('touchstart', handleActivity);

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []); // Empty dependency array to run effect only once on mount

  return null;
};

export default useAutoLogoff;

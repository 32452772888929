import React from 'react';

const ProgressBar = (props) => {
  const { bgcolor, completed } = props;

  const containerStyles = {

    height: 15,
    width: '50%',
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    // margin: '100px auto', // Adjusted margin for centering horizontally
  };

  const fillerStyles = {
    height: '100%',
    width: `${Math.round(completed)}%`, // Ensure completed percentage is integer
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right'
  };


  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        {/* <span style={labelStyles}>{`${Math.round(completed)}%`}</span> */}
      </div>
    </div>
  );
};

export default ProgressBar;

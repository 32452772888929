


import React, { useEffect } from 'react';
import NavBar from "../Landing_page/NavBar_land";
import Body from "../Landing_page/Body";
import "../css/LandingPage.css";

const LandingPage = () => {
  useEffect(() => {
    // Create and append the Google Analytics script
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-N5ENG8N030';
    document.head.appendChild(script);

    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-N5ENG8N030');
    `;
    document.head.appendChild(inlineScript);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
      document.head.removeChild(inlineScript);
    };
  }, []);

  return (
    <div className="landing-page-container">
      <div className="nav-bar">
        <NavBar />
      </div>
      
      <div className="main-section">
        <Body />
      </div>
      
      <div className="landing-footer">
        <p>
          BAIA: Your AI-Powered Emotional Support Assistant{' '}
          <a href="https://www.baia.ai/terms-and-condition/" target="_blank" rel="noopener noreferrer">Terms</a>,{' '}
          <a href="https://www.baia.ai/disclaimer/" target="_blank" rel="noopener noreferrer">Disclaimer</a>{' '}
          and <a href="https://www.baia.ai/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> || Version: Beta-4.0
        </p>
      </div>
    </div>
  );
};

export default LandingPage;

import { memo, useContext, useEffect, useState } from "react";
import { Button, Dialog } from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import "./MainSection.css";
import AuthContext from "../AuthContext";
import img from "../images/land.png";
import hipaaIcon from "../images/HIPAA_L4B-1-300x300.jpg";
import ccpaIcon from "../images/L4B_CCPA-1-300x167.jpg";
import gdprIcon from "../images/L4B_GPDR-1-300x300.jpg";
import ConsentForm from "./ConsentForm";

const MainSection = memo(() => {
  const navigate = useNavigate();
  const { loggedIn, handleLogin } = useContext(AuthContext);
  const location = useLocation();
  const [openConsentForm, setOpenConsentForm] = useState(false);

  useEffect(() => {
    if (loggedIn && location.pathname !== "/loading") {
      navigate("/loading");
    }
  }, [loggedIn, navigate, location]);

  useEffect(() => {
    sessionStorage.setItem("Chat_flag", false);
    if (loggedIn) {
      navigate("/loading");
    }
  }, [loggedIn, navigate]);

  const handleStartClick = () => {
    localStorage.setItem("Logged_by", "signup");
    setOpenConsentForm(true);
  };

  const handleConsentSubmit = () => {
    setOpenConsentForm(false);
    handleLogin();
  };

  return (
    <div className="main">
      <div className="TextMain">
        <h1>BAIA, Your Personal Companion for Emotional Well-being</h1>
        <p>
          BAIA is powered by an advanced AI, built on a foundation of
          specialized data, integrating deep learning and tailored approaches to
          offer personalized emotional care.
        </p>

        <div className="website-view">
          <div
            className="image-section"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1, paddingRight: "20px", textAlign: "center" }}>
              <p>
                <strong>Short Sessions:</strong> 5 to 15 minutes—perfect for
                your busy day.
                <br />
                <strong>Always Ready:</strong> No scheduling needed. BAIA is
                available anytime.
                <br />
                <strong>Accessible Anywhere:</strong> Get help wherever you
                are—at home, work, or on the go.
              </p>
              
                <Button
                    onClick={handleStartClick}
                    variant="outlined"
                    sx={{
                    textTransform: "none",
                    color: "white",
                    fontSize: "13px",
                    bgcolor: "rgb(53, 2, 58)",
                    borderRadius: "32px",
                    "&:hover": { bgcolor: "rgb(53, 2, 58)" },
                    minWidth: 150,
                    padding: "10px 20px",
                    whiteSpace: "normal",
                    textAlign: "center",
                    lineHeight: 1.2,
                    width: "auto",
                    maxWidth: "100%",
                    }}
                >
                    Start Your 7-day Free Trial
                </Button>
                </div>


              {/* <Button
                onClick={handleStartClick}
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: "white",
                  fontSize: "13px",
                  bgcolor: "rgb(53, 2, 58)",
                  borderRadius: "32px",
                  "&:hover": { bgcolor: "rgb(53, 2, 58)" },
                  minWidth: 150,
                  padding: "10px 20px",
                  whiteSpace: "normal",
                  textAlign: "center",
                  lineHeight: 1.2,
                  width: "auto",
                  maxWidth: "100%",
                }}
              >
                Start Your 7-day Free Trial
              </Button> */}
            

            <img
              src={img}
              alt="Descriptive Alt Text"
              style={{
                maxWidth: "50%",
                height: "auto",
                marginLeft: "20px",
              }}
            />
          </div>

          <p>Supported languages: English, Français, Español</p>

          <div
            className="icon-section"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={hipaaIcon}
              alt="HIPAA Compliance"
              style={{ width: "50px", height: "auto", marginRight: "10px" }}
            />
            <img
              src={ccpaIcon}
              alt="CCPA Compliance"
              style={{ width: "50px", height: "auto", marginRight: "10px" }}
            />
            <img
              src={gdprIcon}
              alt="GDPR Compliance"
              style={{ width: "50px", height: "auto", marginRight: "10px" }}
            />
          </div>
          <br />
        </div>

        <div className="mobile-view">
        <div
            className="image-section-1"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ textAlign: "center", marginBottom: "20px" }}>
              <strong>Short Sessions:</strong> 5 to 15 minutes—perfect for your busy day.
              <br />
              <strong>Always Ready:</strong> No scheduling needed. BAIA is available anytime.
              <br />
              <strong>Accessible Anywhere:</strong> Get help wherever you are—at home, work, or on the go.
            </p>
            <img
              src={img}
              alt="Descriptive Alt Text"
              className="mobile-only-image"
              style={{
                maxWidth: "100%",
                height: "auto",
                marginBottom: "20px",
                marginLeft: "10px",
                borderRadius: "10px",
              }}
            />
            <Button
              onClick={handleStartClick}
              variant="outlined"
              sx={{
                textTransform: "none",
                color: "white",
                fontSize: "13px",
                bgcolor: "rgb(53, 2, 58)",
                borderRadius: "32px",
                "&:hover": { bgcolor: "rgb(53, 2, 58)" },
                minWidth: 150,
                padding: "10px 20px",
                whiteSpace: "normal",
                textAlign: "center",
                lineHeight: 1.2,
                width: "auto",
                maxWidth: "100%",
              }}
            >
              Start Your 7-day Free Trial
            </Button>
          </div>

          {/* <div
            className="image-section-1"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ textAlign: "center" }}>
              <strong>Short Sessions:</strong> 5 to 15 minutes—perfect for
              your busy day.
              <br />
              <strong>Always Ready:</strong> No scheduling needed. BAIA is
              available anytime.
              <br />
              <strong>Accessible Anywhere:</strong> Get help wherever you
              are—at home, work, or on the go.
            </p>
            <img
              src={img}
              alt="Descriptive Alt Text"
              className="mobile-only-image"
              style={{
                maxWidth: "100%",
                height: "auto",
                marginBottom: "20px",
                marginLeft: "10px",
                borderRadius: "10px",
              }}
            />
            <Button
              onClick={handleStartClick}
              variant="outlined"
              sx={{
                textTransform: "none",
                color: "white",
                fontSize: "13px",
                bgcolor: "rgb(53, 2, 58)",
                borderRadius: "32px",
                "&:hover": { bgcolor: "rgb(53, 2, 58)" },
                minWidth: 150,
                padding: "10px 20px",
                whiteSpace: "normal",
                textAlign: "center",
                lineHeight: 1.2,
                width: "auto",
                maxWidth: "100%",
              }}
            >
              Start Your 7-day Free Trial
            </Button>
          </div> */}

          <p>Supported languages: English, Français, Español</p>
        </div>

        <div className="mobile-view">
          <div
            className="icon-section"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={hipaaIcon}
              alt="HIPAA Compliance"
              style={{ width: "50px", height: "auto", marginRight: "10px" }}
            />
            <img
              src={ccpaIcon}
              alt="CCPA Compliance"
              style={{ width: "50px", height: "auto", marginRight: "10px" }}
            />
            <img
              src={gdprIcon}
              alt="GDPR Compliance"
              style={{ width: "50px", height: "auto", marginRight: "10px" }}
            />
          </div>
          <br />
        </div>
      </div>

      <Dialog open={openConsentForm} onClose={() => setOpenConsentForm(false)}>
        <ConsentForm onSubmit={handleConsentSubmit} />
      </Dialog>
    </div>
  );
});

MainSection.propTypes = {
  className: PropTypes.string,
};

export default MainSection;




import React, { useEffect } from 'react';
import { Box, Typography, IconButton, Link, useMediaQuery } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import AssessmentIcon from '@mui/icons-material/AssignmentTurnedIn';
import ProfileIcon from '@mui/icons-material/Person';
import MicIcon from '@mui/icons-material/Mic';

const AlertMessage = ({
  showAssessmentAlert,
  handleCloseAssessmentAlert,
  showProfileAlert,
  handleCloseProfileAlert,
  showConsentAlert,
  handleCloseConsentAlert
}) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (showAssessmentAlert) {
      const timer = setTimeout(handleCloseAssessmentAlert, 3000);
      return () => clearTimeout(timer);
    }
  }, [showAssessmentAlert, handleCloseAssessmentAlert]);

  useEffect(() => {
    if (showProfileAlert) {
      const timer = setTimeout(handleCloseProfileAlert, 3000);
      return () => clearTimeout(timer);
    }
  }, [showProfileAlert, handleCloseProfileAlert]);

  useEffect(() => {
    if (showConsentAlert) {
      const timer = setTimeout(handleCloseConsentAlert, 3000);
      return () => clearTimeout(timer);
    }
  }, [showConsentAlert, handleCloseConsentAlert]);

  return (
    <>
      {showAssessmentAlert && (
        <Box
          className="assessment-alert"
          position="fixed"
          zIndex={999}
          bottom={isMobile ? 105 : 105}
          left={isMobile ? 10 : 30}
          p={2}
          bgcolor="white"
          boxShadow={3}
          color="black"
          borderRadius={5}
          width={isMobile ? '50%' : '320px'}
          fontFamily={'Roboto'}
          sx={{
            fontSize: isMobile ? '0.9rem' : '1rem',
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Box display="flex" alignItems="center">
              <AssessmentIcon sx={{ color: 'black', marginRight: 1 }} />
              <Typography variant="h6" fontWeight="bold" sx={{ fontSize: isMobile ? '1rem' : '1.25rem' }}>
                Pending Assessments
              </Typography>
            </Box>
            <IconButton onClick={handleCloseAssessmentAlert}>
              <CancelSharpIcon sx={{ color: 'black' }} />
            </IconButton>
          </Box>
          <Typography variant="body1" gutterBottom sx={{ fontSize: isMobile ? '0.8rem' : '1rem' }}>
            You have pending assessments. Click the link below to complete them:
          </Typography>
          <Link component={RouterLink} to="/assessment">
            Go to Assessments
          </Link>
        </Box>
      )}

      {showProfileAlert && (
        <Box
          className="profile-alert"
          position="fixed"
          zIndex={999}
          bottom={isMobile ? 105 : 105}
          right={isMobile ? 10 : 28}
          p={2}
          bgcolor="white"
          boxShadow={3}
          color="black"
          borderRadius={5}
          width={isMobile ? '80%' : '300px'}
          fontFamily={'Roboto'}
          sx={{
            fontSize: isMobile ? '0.9rem' : '1rem',
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Box display="flex" alignItems="center">
              <ProfileIcon sx={{ color: 'black', marginRight: 1 }} />
              <Typography variant="h6" fontWeight="bold" sx={{ fontSize: isMobile ? '1rem' : '1.25rem' }}>
                Profile Alert
              </Typography>
            </Box>
            <IconButton onClick={handleCloseProfileAlert}>
              <CancelSharpIcon sx={{ color: 'black' }} />
            </IconButton>
          </Box>
          <Typography variant="body1" gutterBottom sx={{ fontSize: isMobile ? '0.8rem' : '1rem' }}>
            You have missing profile data fields. Click the link below to update your profile:
          </Typography>
          <Link component={RouterLink} to="/profile">
            Go to Profile
          </Link>
        </Box>
      )}

      {showConsentAlert && (
        <Box
          className="consent-alert"
          position="fixed"
          zIndex={999}
          bottom={isMobile ? 105 : 105}
          right={isMobile ? 10 : 28}
          p={2}
          bgcolor="white"
          boxShadow={3}
          color="black"
          borderRadius={5}
          width={isMobile ? '80%' : '300px'}
          fontFamily={'Roboto'}
          sx={{
            fontSize: isMobile ? '0.9rem' : '1rem',
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Box display="flex" alignItems="center">
              <MicIcon sx={{ color: 'black', marginRight: 1 }} />
              <Typography variant="h6" fontWeight="bold" sx={{ fontSize: isMobile ? '1rem' : '1.25rem' }}>
                Voice Consent Alert
              </Typography>
            </Box>
            <IconButton onClick={handleCloseConsentAlert}>
              <CancelSharpIcon sx={{ color: 'black' }} />
            </IconButton>
          </Box>
          <Typography variant="body1" gutterBottom sx={{ fontSize: isMobile ? '0.8rem' : '1rem' }}>
            Please provide your voice consent to enable voice functionality.
          </Typography>
          <Link component={RouterLink} to="/setting">
            Go to settings
          </Link>
        </Box>
      )}
    </>
  );
};

export default AlertMessage;


import { memo ,useEffect} from "react";
import MainSection from "./MainSection";
import Image1 from "./Image1";
import PropTypes from "prop-types";
import "./Body.css";

const Body = memo(({ className = "" }) => {
  
  useEffect(() => {
    // Reset the timer data in local storage
    localStorage.setItem('timerData', JSON.stringify({ minutes: 0, seconds: 0 }));
  }, []);
  return (
    <div className="">
      <div className="container">
        <MainSection />
      </div>
      {/* <div className="image-section">
        <div className="image">
        <div className="image-placeholder" />
        <div className="active-indicator" />
        <img
          className="inactive-indicator-icon"
          alt=""
          src="/rectangle-37@2x.png"
        />
        </div>
     
      </div> */}
    </div>
    
  );
});

Body.propTypes = {
  className: PropTypes.string,
};

export default Body;




import { useEffect } from "react";
import MyProfile from "./Profile/MyProfile";
import HomePage from "./pages/HomePage";
import LandingPage from "./pages/LandingPage";
import AssessmentDetail from "./Assessment/AssessmentDetail";
import MyAssessment from "./Assessment/MyAssessment";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AssessmentProvider } from "./context/AssessmentContext";
import MoodAnalysis from "./Analysis/MoodAnalysis";
import { ProfilePicProvider} from "./context/ProfilePicContext";
import { VoicePreferencesProvider } from "./context/VoicePreferencesContext";
import { ProfileImageContextProvider } from "./context/ProfileImageContext";
import MySubscription from "./Subscription/MySubscription";
import Feedback from "./Feedback/MyFeedback";
import { AuthProvider } from './AuthContext';
// import useAutoLogoff from './useAutoLogoff'; // Adjust the path as necessary
import SettingPage from './SettingsPage/setting';
import { TimerProvider } from "./context/TimerContext";
import { LanguageProvider } from "./context/LanguageContext";
import LoadingComponent from "./Loading_page";
// import BaiaIcon from "./BaiaIcon";
import ManageAccount from "./ManageAccount/ManageAccount"
import ProtectedRoute from './PrivateRoute'

function App() {

  // useAutoLogoff(); // Automatically log off user after 10 minutes of inactivity

  return (
    
    <AuthProvider>
    <ProfileImageContextProvider>
    <ProfilePicProvider> 
    <AssessmentProvider>
    <VoicePreferencesProvider>
    <TimerProvider>
    <LanguageProvider>
    {/* <BaiaIcon /> */}

          <Routes>
          {/* <Route path="/signin" component={SignUp} /> */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/loading" element={<LoadingComponent />} />
          <Route path="/home" element={<ProtectedRoute element={<HomePage />} />} />
          <Route path="/assessment" element={<ProtectedRoute element={<MyAssessment />} />} />
          <Route path="/assessment/:assessmentType" element={<ProtectedRoute element={<AssessmentDetail />} />} />
          <Route path="/analysis" element={<ProtectedRoute element={<MoodAnalysis />} />} />
          <Route path="/profile" element={<ProtectedRoute element={<MyProfile />} />} />
          <Route path="/subscription" element={<ProtectedRoute element={<MySubscription />} />} />
          <Route path="/feedback" element={<ProtectedRoute element={<Feedback />} />} />
          <Route path="/setting" element={<ProtectedRoute element={<SettingPage />} />} />
          <Route path="/manage-account" element={<ProtectedRoute element={<ManageAccount />} />} />


          </Routes>

          </LanguageProvider>
          </TimerProvider>
          </VoicePreferencesProvider>
          </AssessmentProvider>
          </ProfilePicProvider> 
          </ProfileImageContextProvider>
          </AuthProvider>
  );
}
export default App;



import React from 'react';
import ApexCharts from 'react-apexcharts';

class ApexChart_1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          height: 500,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 360,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
            },
            dataLabels: {
              name: {
                show: true,
                fontSize: '18px',
              },
              value: {
                show: true,
                fontSize: '18px',
              },
            },
            track: {
              margin: 5,
              strokeWidth: '90%',
              background: '#e7e7e7', // Optional: change the background color of the track
              strokeWidth: '100%', // Full width of the stroke
              colors: [], // The border color will be handled by the stroke.colors
              stroke: {
                width: 3, // Adjust the width of the border
                colors: [], // Add border colors dynamically
              },
            },
          },
        },
        labels: [],
        colors: [], // Colors for the radial bars
        legend: {
          show: true,
          floating: true,
          fontSize: '15px',
          position: 'left',
          offsetX: -10,
          offsetY: 10,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 3,
          },
          formatter: function (seriesName, opts) {
            return `${seriesName}: ${opts.w.globals.series[opts.seriesIndex]}%`;
          },
          itemMargin: {
            vertical: 4,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                height: 390,
              },
              legend: {
                fontSize: '12px',
                itemMargin: {
                  vertical: 2,
                },
              },
              plotOptions: {
                radialBar: {
                  startAngle: 30,
                  endAngle: 270,
                  hollow: {
                    size: '10%',
                  },
                  track: {
                    margin: 8,
                    strokeWidth: '80%',
                  },
                  dataLabels: {
                    name: {
                      fontSize: '10px',
                    },
                    value: {
                      fontSize: '10px',
                    },
                  },
                },
              },
            },
          },
        ],
      },
      explanations: [],
    };
  }

  componentDidMount() {
    this.renderCharts();
  }

  renderCharts = () => {
    const { assessmentData } = this.props;

    if (!assessmentData) {
      return;
    }

    const series = [];
    const labels = [];
    const colors = [];
    const explanations = [];
    const borderColors = [];

    Object.keys(assessmentData).forEach((key) => {
      const item = assessmentData[key];
      if (item) {
        let percentage;
        let label;
        let color;
        let explanation;
        let border;
        switch (key) {
          case 'anxiety_data':
            percentage = item.anxiety_severity_percentage;
            label = 'Anxiety';
            color = 'rgba(82, 4, 85, 0.781)';
            border='rgba(82, 4, 85, 0.781)';
            explanation = item.explanation;
            break;
          case 'depression_data':
            percentage = item.depression_severity_percentage;
            label = 'Depression';
            color = 'rgb(120, 6, 146)';
            border='rgb(120, 6, 146)';
            explanation = item.explanation;
            break;
          case 'relationship_data':
            percentage = item.relationship_satisfaction_percentage;
            label = 'Relationship Satisfaction';
            color = 'rgb(221, 110, 247)';
            border='rgb(221, 110, 247)'
            explanation = item.explanation;
            break;
          case 'eating_disorder_data':
            percentage = item.eating_disorder_severity_percentage;
            label = 'Eating Disorder';
            color = 'rgb(190, 134, 210)';
            border= 'rgb(190, 134, 210)'
            explanation = item.explanation;
            break;
          case 'intolerance_factor_a_data':
            percentage = item.intolerance_factor_a_percentage;
            label = 'Intolerance Factor A';
            color = 'rgba(140, 106, 148, 0.705)';
            border='rgba(140, 106, 148, 0.705)';
            explanation = item.explanation;
            break;
          case 'intolerance_factor_b_data':
            percentage = item.intolerance_factor_b_percentage;
            label = 'Intolerance Factor B';
            color = '#FF4560';
            border='#FF4560';
            explanation = item.explanation;
            break;
          case 'intolerance_data':
            percentage = item.intolerance_severity_percentage;
            label = 'Intolerance';
            color = 'rgba(197, 150, 199, 0.705)';
            border='rgba(197, 150, 199, 0.705)';
            explanation = item.explanation;
            break;
          case 'polarity_disorder_data':
            percentage = item.bipolar_disorder_percentage;
            label = 'Polarity Disorder';
            color = 'rgba(140, 106, 148, 0.705)';
            border='rgba(140, 106, 148, 0.705)';
            explanation = item.explanation;
            break;
          default:
            percentage = 0;
            label = 'Unknown';
            color = '#000';
            explanation = 'No explanation available';
            break;
        }

        if (percentage) {
          series.push(percentage);
          labels.push(label);
          colors.push(color);
          borderColors.push(color); // Use the same color for the border
          explanations.push(explanation);
        }
      }
    });

    this.setState({
      series,
      options: {
        ...this.state.options,
        labels,
        colors,
        plotOptions: {
          ...this.state.options.plotOptions,
          radialBar: {
            ...this.state.options.plotOptions.radialBar,
            track: {
              ...this.state.options.plotOptions.radialBar.track,
              stroke: {
                width: 3,
                colors: borderColors, // Set the border colors
                
              },
            },
          },
        },
      },
      explanations,
    });
  };

  render() {
    const { series, options, explanations } = this.state;

    return (
      <div className='chart-container'>
        <div className="chart-container_main">
          <div className="explanation-cards">
            {explanations.map((explanation, index) => (
              <div
                key={index}
                className="explanation-card"
                style={{
                  backgroundColor: options.colors[index],
                  padding: '10px',
                  margin: '10px 0',
                  borderRadius: '8px',
                  color: '#fff',
                }}
              >
                <h4>{options.labels[index]}</h4>
                <p>{explanation}</p>
              </div>
            ))}
          </div>
        </div>
        <div className='radial-chart'>
          <ApexCharts
            options={options}
            series={series}
            type="radialBar"
            height={options.chart.height}
          />
        </div>
      </div>
    );
  }
}

export default ApexChart_1;

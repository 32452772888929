
import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import NavBar from '../HomePage/NavBar_home';
import { useNavigate } from 'react-router-dom';
import StripePricingTable from '../StripePricingTable';
import baia from "../images/subscription.png";
import "./subscription.css"
const MySubscription = () => {
  const navigate = useNavigate();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});

  useEffect(() => {
    const fetchSubscriptionDetails = () => {
      const availableSubscription = JSON.parse(localStorage.getItem('available_subscription'));
      // console.log('Available Subscription:', availableSubscription); // Check if this prints correctly

      if (availableSubscription) {
        const productName = JSON.parse(localStorage.getItem('product_name'));
        const subscriptionStatus = JSON.parse(localStorage.getItem('subscription_status'));
        // console.log('Product Name:', productName); // Check if this prints correctly
        // console.log('Subscription Status:', subscriptionStatus); // Check if this prints correctly

        setSubscriptionDetails({ productName, subscriptionStatus });
        setIsSubscribed(true);
      }
    };

    fetchSubscriptionDetails();
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  return (

<Box className="subscription-container">
      <NavBar />
      {isSubscribed ? (
        <Box className="subscription-content">
          <Typography variant="h4" gutterBottom className="subscription-title">
            Your Current Subscription Plan
          </Typography>
          <Box className="subscription-banner">
            <img src={baia} alt="Subscription Banner" />
          </Box>
          <TableContainer component={Paper} className="subscription-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="subscription-table-header">Product Name</TableCell>
                  <TableCell className="subscription-table-header">Subscription Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="subscription-table-cell">{subscriptionDetails.productName}</TableCell>
                  <TableCell className="subscription-table-cell">{subscriptionDetails.subscriptionStatus ? 'Active' : 'Inactive'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="h6" gutterBottom className="subscription-subtitle">
            Comprehensive Status of the Active Subscription Plan
          </Typography>
          <Typography variant="body1" gutterBottom className="subscription-description">
  To handle your payment and subscription, kindly
    click below 
 
  and log in using the official email address you used to register in BAIA.
</Typography>
<Box textAlign="center" mt={3}>
  <Button variant="contained" color="primary" href="https://billing.stripe.com/p/login/bIYg0d1ut5m4gsocMM" target="_blank" className="manage-subscription-button">
    Manage Subscription
  </Button>
</Box>

        </Box>
      ) : (
        <Box>
          <StripePricingTable />
        </Box>
      )}
 <div className="home-footer">
  <p>
    BAIA: Your AI-Powered Emotional Support Assistant{' '}
    <a
      href="https://www.baia.ai/terms-and-condition/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue' }}
    >
      Terms
    </a>,{' '}
    <a
      href="https://www.baia.ai/disclaimer/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue' }}
    >
      Disclaimer
    </a>{' '}
    and{' '}
    <a
      href="https://www.baia.ai/privacy-policy/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue' }}
    >
      Privacy Policy
    </a> || Version: Beta-4.0
  </p>
</div>
</Box>
  );
};

export default MySubscription;


import { memo, useContext, useState, useEffect } from "react";
import { Button, Dialog } from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from '../AuthContext';
import ConsentForm from './ConsentForm';
import "./NavBar_land.css";


const NavBar = memo(({ className = "" }) => {
  
  
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedIn, userInfo, handleLogin, handleLogout } = useContext(AuthContext);
  const [openConsentForm, setOpenConsentForm] = useState(false);
  
  useEffect(() => {
    sessionStorage.setItem('Chat_flag',false);
    // console.log(loggedIn)
    if (loggedIn) {
      navigate('/loading');
    }
  }, [loggedIn, navigate, location]);

  const handleSignupClick = () => {
    localStorage.setItem('Logged_by','singup')

    setOpenConsentForm(true);
  };
const handleLoginInClick=()=>{
  localStorage.setItem('Logged_by','login')
  handleLogin()
}
  const handleConsentSubmit = () => {
    setOpenConsentForm(false);
    // Call loggedIn logic here
    handleLogin();
  };
  const handleClick = () => {
    const chatType = localStorage.getItem('chatType');
    window.open('https://www.baia.ai', '_blank'); // Open URL in a new tab
  }
  
  return (
    <div className={`navbar ${className}`}>
      {/* <div onClick={handleClick}> */}
      <img
        className="baia-logo-icon"
        loading="lazy"
        alt=""
        src="/baia-logo@2x.png"
        onClick={handleClick}
      />
      {/* </div> */}
     
      <div className="auth-buttons">
        <Button
          onClick={handleLoginInClick}
          className="login-button"
          variant="contained"
          sx={{
            textTransform: "none",
            color: "#fff",
            fontSize: "14",
            background: "#7867ae",
            borderRadius: "26px",
            "&:hover": { background: "rgb(53, 2, 58)" },
            height: 46,
          }}
        >
          Log In
        </Button>
        <Button
          onClick={handleSignupClick}
          className="signin-button"
          variant="contained"
          sx={{
            textTransform: "none",
            color: "black",
            fontSize: "14",
            background: "white",
            borderRadius: "26px",
            border:"2px solid #7867ae",
            "&:hover": { background: "rgb(53, 2, 58,0.2)" },
            height: 46,
          }}
        >
          Get Started
        </Button>
      </div>
      <Dialog open={openConsentForm} onClose={() => setOpenConsentForm(false)}>
        <ConsentForm onSubmit={handleConsentSubmit} />
      </Dialog>
    </div>
  );
});

NavBar.propTypes = {
  className: PropTypes.string,
};

export default NavBar;



import React,{useState} from 'react';
import { Box, Grid, TextField, Button, MenuItem } from '@mui/material';

const ProfileForm = ({
  profile,
  handleInputChange,
  handleCountryChange,
  handleCurrentCountryChange,
  errors,
  countries,
  cities,
  loadingCities,
  mode,
  onSave,
  onCancel
}) => {
  const personalFields = [
    { field: 'first_name', value: profile.first_name, error: errors.first_name, helperText: errors.first_name },
    { field: 'family_name', value: profile.family_name, error: errors.family_name, helperText: errors.family_name },
    { field: 'DOB', value: profile.DOB },
    { field: 'gender', value: profile.gender, options: ["Woman", "Man", "Non-binary", "Self identify", "Prefer not to say", "None"] },
    { field: 'religion', value: profile.religion, options: ["Christianity", "Islam", "Hinduism", "Judaism", "Spiritual", "Other", "None"]},
    { field: 'marital_status', value: profile.marital_status, options: ["Single", "In a relationship", "Married", "Divorced", "Widowed/er", "Its complicated", "None"] },
    { field: 'hobbies', value: profile.hobbies },
  ];

  const addressFields = [
    { field: 'country_born_in', value: profile.country_born_in, options: countries, handleChange: handleCountryChange },
    { field: 'current_country', value: profile.current_country, options: countries, handleChange: handleCurrentCountryChange },
    { field: 'city', value: profile.city, options: cities, loading: loadingCities },
  ];

  const fields = mode === 'personal' ? personalFields : addressFields;
  // Handle date changes specifically for DOB
  const [error, setError] = useState({});
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    const today = new Date();
    const birthDate = new Date(value);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    if (age < 18) {
        handleInputChange({ target: { name, value: '' } }); // Reset the value
        setError((prevErrors) => ({ ...prevErrors, [name]: 'You must be 18 years or older.' })); // Set the error message
        window.alert('You must be 18 years or older.');
    } else {
        handleInputChange({ target: { name, value } });
        setError((prevErrors) => ({ ...prevErrors, [name]: '' })); // Clear any previous error message
    }
};


  return (
    <Box sx={{ marginTop: 2 }}>
    <Grid container spacing={2}>
      {fields.map((fieldData, index) => {
        const isDateField = fieldData.field === 'DOB';

        return (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <TextField
              type={isDateField ? 'date' : 'text'}
              name={fieldData.field}
              value={fieldData.value || ''}
              onChange={isDateField ? handleDateChange : (fieldData.handleChange || handleInputChange)}
              fullWidth
              variant="outlined"
              label={fieldData.field.replace('_', ' ')}
              error={Boolean(fieldData.error)}
              helperText={fieldData.error}
              select={fieldData.options ? true : false}
              disabled={fieldData.loading}
              InputLabelProps={isDateField ? { shrink: true } : undefined}
              sx={{
                backgroundColor: '#f9f9f9', // Background color for edit mode
                maxWidth: '100%', // Ensures the field uses the available width but doesn't exceed its container
                fontFamily: 'Roboto', // Font family for the text field
              }}
            >
              {fieldData.options && fieldData.options.map((option, idx) => (
                <MenuItem key={idx} value={option}>{option}</MenuItem>
              ))}
            </TextField>
          </Grid>
        );
      })}
    </Grid>
    <Box display="flex" justifyContent="flex-end" mt={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={onSave}
        sx={{
          marginRight: 2,
          backgroundColor: 'rgb(96, 8, 136)', // Background color for Save button
          '&:hover': {
            backgroundColor: 'rgb(96, 8, 136)', // Hover background color for Save button
          },
        }}
      >
        Save
      </Button>
      <Button
        variant="outlined"
        onClick={onCancel}
        sx={{
          color: 'rgb(96, 8, 136)',
          border: '1px solid rgb(96, 8, 136)',
          '&:hover': {
            border: '1px solid rgb(96, 8, 136)', // Hover border color for Cancel button
          },
        }}
      >
        Cancel
      </Button>
    </Box>
  </Box>
  );
};

export default ProfileForm;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.body{
  
  font-family: 'roboto-thin';

}
.container {
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  margin-top: 0%;
  left: 0;
  width: 100%;
  /* min-width: 500px; */
  height: 100vh;
  background-color: rgba(233, 183, 243, 0.144);
  /* border: 2px solid rgb(163, 37, 37); */
  /* gap:50px */
}
/* 
.main-section {
  flex: 1; 
  margin-left: 5%;
border: 1px solid;
width: 80%;
} */

.image-section {
  flex: 1 1; 
  
  
}




/* Mobile View Adjustments */
@media (max-width: 1040px) {
  
  .container {
   
    margin-top: 0%;
    width: 100%;
    
    height: 100vh;
    /* border: 2px solid rgb(163, 37, 37); */
    /* gap:5%; */
    display: flex;
    background-color: rgba(233, 183, 243, 0.144);
    flex-direction: column;
    /* margin-bottom: 20px; */
   
  }
  .image {
    display:none;
   
  }


  
}`, "",{"version":3,"sources":["webpack://./src/Landing_page/Body.css"],"names":[],"mappings":";AACA;;EAEE,0BAA0B;;AAE5B;AACA;EACE,wBAAwB;EACxB,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,OAAO;EACP,WAAW;EACX,sBAAsB;EACtB,aAAa;EACb,4CAA4C;EAC5C,wCAAwC;EACxC,aAAa;AACf;AACA;;;;;;GAMG;;AAEH;EACE,SAAO;;;AAGT;;;;;AAKA,4BAA4B;AAC5B;;EAEE;;IAEE,cAAc;IACd,WAAW;;IAEX,aAAa;IACb,wCAAwC;IACxC,YAAY;IACZ,aAAa;IACb,4CAA4C;IAC5C,sBAAsB;IACtB,yBAAyB;;EAE3B;EACA;IACE,YAAY;;EAEd;;;;AAIF","sourcesContent":["\n.body{\n  \n  font-family: 'roboto-thin';\n\n}\n.container {\n  /* position: absolute; */\n  display: flex;\n  flex-direction: row;\n  margin-top: 0%;\n  left: 0;\n  width: 100%;\n  /* min-width: 500px; */\n  height: 100vh;\n  background-color: rgba(233, 183, 243, 0.144);\n  /* border: 2px solid rgb(163, 37, 37); */\n  /* gap:50px */\n}\n/* \n.main-section {\n  flex: 1; \n  margin-left: 5%;\nborder: 1px solid;\nwidth: 80%;\n} */\n\n.image-section {\n  flex: 1; \n  \n  \n}\n\n\n\n\n/* Mobile View Adjustments */\n@media (max-width: 1040px) {\n  \n  .container {\n   \n    margin-top: 0%;\n    width: 100%;\n    \n    height: 100vh;\n    /* border: 2px solid rgb(163, 37, 37); */\n    /* gap:5%; */\n    display: flex;\n    background-color: rgba(233, 183, 243, 0.144);\n    flex-direction: column;\n    /* margin-bottom: 20px; */\n   \n  }\n  .image {\n    display:none;\n   \n  }\n\n\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem, IconButton, Box, Avatar, Button } from '@mui/material';
import "./Ass_detail.css"

const AssessmentNav = memo(({ className = "" }) => {
  const navigate = useNavigate();
 
  return (
    <div >
    <div className="chat-parent">
      <Button
        variant="contained"
        color="secondary"
        className="end-button"
        onClick={() => navigate('/assessment')}
      >
        End
      </Button>
    </div>

    </div>
  );
});

AssessmentNav.propTypes = {
  className: PropTypes.string,
};

export default AssessmentNav;

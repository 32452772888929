// context/VoicePreferencesContext.js
import React, { createContext, useState } from 'react';

export const VoicePreferencesContext = createContext();

export const VoicePreferencesProvider = ({ children }) => {
  const [voicePreferences, setVoicePreferences] = useState({
    preferredGender: 'Female',
    preferredCharacter: 'BAIA-F-01_English-US',
  });

  const updateVoicePreferences = (preferences) => {
    setVoicePreferences((prev) => ({ ...prev, ...preferences }));
  };

  return (
    <VoicePreferencesContext.Provider value={{ voicePreferences, updateVoicePreferences }}>
      {children}
    </VoicePreferencesContext.Provider>
  );
};

import NavBar from "../HomePage/NavBar_home";
import FinalBody from "../HomePage/FinalBody";
import "../css/HomePage.css";
import { getSessionData } from '../utils/session';
import React, { useContext, useEffect,useState } from 'react';
// import AuthContext from '../AuthContext';
import { useTimer } from "../context/TimerContext";
import WatchLaterIcon from '@mui/icons-material/WatchLater';




const HomePage = () => {
  const [totalTimer, setTotalTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [showTimerDetails, setShowTimerDetails] = useState(false);

  const totalSeconds = JSON.parse(sessionStorage.getItem('availed-time'));
  const hours = Math.floor(totalSeconds / 3600) || 0;
  const minutes = Math.floor((totalSeconds % 3600) / 60) || 0;
  const seconds = totalSeconds % 60 || 0;

  const { localTimer } = useTimer();

  useEffect(() => {
    setTotalTime({ hours, minutes, seconds });
  }, [hours, minutes, seconds]);


  const handleIconClick = () => {
    setShowTimerDetails(!showTimerDetails);
  }
  return (
    <div className="home-page">
      <div>
        <FinalBody />
      </div>
      <div className="timer-container">
      <div className={`timer-details ${showTimerDetails ? 'open' : ''}`}>
        <div className="timers">
        <div className="session-time">Session Time: {`${localTimer.hours || 0}h:${localTimer.minutes || 0}m:${localTimer.seconds || 0}s`}</div>
        <div className="total-time">Total Time: {`${totalTimer.hours || 0}h:${totalTimer.minutes || 0}m:${totalTimer.seconds || 0}s`}</div>
        </div>
       
        <div className="time-display-box" onClick={handleIconClick}>
      <WatchLaterIcon className="timer-icon" />
        
      </div>
      </div>
      
    </div>
    <div className="home-footer">
  <p>
    BAIA: Your AI-Powered Emotional Support Assistant{' '}
    <a
      href="https://www.baia.ai/terms-and-condition/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue' }}
    >
      Terms
    </a>,{' '}
    <a
      href="https://www.baia.ai/disclaimer/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue' }}
    >
      Disclaimer
    </a>{' '}
    and{' '}
    <a
      href="https://www.baia.ai/privacy-policy/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue' }}
    >
      Privacy Policy
    </a> || Version: Beta-4.0
  </p>
</div>

    </div>
  );
};

export default HomePage;


import React from 'react';
import ReactApexChart from 'react-apexcharts';

class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          height: 150,
          type: 'radialBar',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '70%',
              background: '#fff', // Keep the hollow background white
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: '#eee', // Set track color to light gray
              strokeWidth: '67%',
              margin: 0,
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },
            dataLabels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                formatter: function (val) {
                  return `${val}%`; // Display the value with a percentage sign
                },
                color: 'rgb(70, 3, 90)', // Set text color to black
                fontSize: '15px',
                show: true,
              }
            }
          }
        },
        fill: {
          type: 'solid', // Use solid fill
          colors: ['rgb(70, 3, 90)'] // Set fill color to black
        },
        stroke: {
          lineCap: 'round',
          colors: ['rgb(70, 3, 90)'], // Set stroke color to black
          width: 4
        },
        labels: ['Percent'],
      },
    };
  }

  render() {
    const { score } = this.props;
    const scaledScore = (score / 5) * 100; // Scale score to percentage

    return (
      <ReactApexChart
        options={this.state.options}
        series={[scaledScore]}
        type="radialBar"
        height={200}
      />
    );
  }
}

export default ApexChart;


// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import {fetchFontSize,fetchCharacterCode,fetchAndRenderImage,fetchFreshChat, fetchCurrentLanguage,checkCentralSubscription,checkInitialSubscription, fetchVoiceConcent, fetchPreviousChat, fetchAvailedTime } from './api';
// import { useProfilePic } from './context/ProfilePicContext'; 
// import { LinearProgress, Box, Typography } from '@mui/material';
// import { styled } from '@mui/system';
// import img from "./Gif/BAIA-WEB-2.gif";
// import "./css/loading.css"



// const LoadingComponent = () => {
//   const userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
//   const user_name = userinfo.username;
//   const email_id = userinfo.email;
//   const { setProfilePic } = useProfilePic();
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();

//   const getEndpoints = (language) => {
//     switch (language) {
//       case 'French':
//         return {
//           fresh: 'api/fresh_french_chat',
//           continuous: 'api/present_continue_french_chat',
//         };
//       case 'Spanish':
//         return {
//           fresh: 'api/fresh_spanish_chat',
//           continuous: 'api/present_continue_spanish_chat',
//         };
//       case 'Hebrew':
//         return {
//           fresh: 'api/fresh_hebrew_chat',
//           continuous: 'api/present_continue_hebrew_chat',
//         };
//       case 'English':
//       default:
//         return {
//           fresh: 'api/fresh_chat',
//           continuous: 'api/present_continue_chat',
//         };
//     }
//   };

//   useEffect(() => {
//     const datafetch = async () => {
//       setIsLoading(true);
//       try {
//         const generateSessionId = () => {
//           return `${new Date().getTime()}-${Math.floor(Math.random() * 1000)}`;
//         };
        
//         // Call this function on login to generate and store the session ID
//         const initializeSessionId = () => {
//           const sessionId = generateSessionId();
//           sessionStorage.setItem('sessionId', sessionId);
//         };
        
//         // Example of calling initializeSessionId on login
//         initializeSessionId();
        
//         sessionStorage.setItem('loggedIn',true)
//         // console.log()
//         await fetchAndRenderImage(user_name,setProfilePic);
//         const time=await fetchAvailedTime(user_name)
//         // console.log('Fetching voice consent...');
//         const voiceConsent = await fetchVoiceConcent(user_name);
//         sessionStorage.setItem('consent', JSON.stringify(voiceConsent.response));
//         // console.log('Fetched voice consent:', voiceConsent);
//         const initialSub = await checkInitialSubscription(user_name);
//         const centralSub = await checkCentralSubscription(user_name,email_id);
//         sessionStorage.setItem('initial_subscription', JSON.stringify(initialSub));
//         sessionStorage.setItem('central_subscription', JSON.stringify(centralSub));
//         // console.log('Fetching current language...');
//         const language = await fetchCurrentLanguage(user_name);
//         sessionStorage.setItem('current_language', language);
//         console.log('Fetched current language:', language);
//         const voiceChar = await fetchCharacterCode(user_name);
//         // console.log('Fetching endpoints...');
//         const { fresh, continuous } = getEndpoints(language);
//         const fontSize=await fetchFontSize(user_name);
//         sessionStorage.setItem('font_size', fontSize)
//         sessionStorage.setItem('freshEndpoint', fresh);
//         sessionStorage.setItem('continuousEndpoint', continuous);
//         // localStorage.setItem("voiceCharacter", 'BAIA-F-03_English-UK');
//         sessionStorage.setItem('freshChat','')
       
//         // sessionStorage.setItem('previousChat',[])
//         // console.log('Fetching previous chat...');
//         const previousChatMessages = await fetchPreviousChat(user_name, 1, continuous);
//         // console.log('Fetched previous chat messages:', previousChatMessages);
        
//         // console.log('Navigating to home...');
//         if(localStorage.getItem('Logged_by')=='login')
//         {navigate('/home');}
//         else{
//           localStorage.setItem('chatType', 'fresh');
//           navigate('/home');
//         }
        
//       } catch (error) {
//         console.error('Error fetching subscriptions:', error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     datafetch();
//   }, [navigate, user_name, email_id]);

//   return (
//     <div className='loading-container'>
//      <div className="loading-content">
//       <img 
//         src={img} 
//         alt="Loading" 
//         className="loading-image" 
//       />
//       <Typography variant="h7" gutterBottom className="loading-text">
//         BAIA is setting up your profile to enhance your experience. Please wait a moment—this won't take long!
//       </Typography>
//     </div>
     
//     </div>
//   );
// };

// export default LoadingComponent;


import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchFontSize, fetchCharacterCode, fetchAndRenderImage, fetchFreshChat, fetchCurrentLanguage, checkCentralSubscription, checkInitialSubscription, fetchVoiceConcent, fetchPreviousChat, fetchAvailedTime } from './api';
import { useProfilePic } from './context/ProfilePicContext'; 
import LocationFetcher from './LocationFetcher'; // Import LocationFetcher component
import { LinearProgress, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import img from "./Gif/BAIA-WEB-2.gif";
import "./css/loading.css";

const LoadingComponent = () => {
  const userinfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const user_name = userinfo.username;
  const email_id = userinfo.email;
  const { setProfilePic } = useProfilePic();
  const [isLoading, setIsLoading] = useState(false);
  const [consent, setConsent] = useState(null); // State for consent
  const navigate = useNavigate();

  const getEndpoints = (language) => {
    switch (language) {
      case 'French':
        return {
          fresh: 'api/fresh_french_chat',
          continuous: 'api/present_continue_french_chat',
        };
      case 'Spanish':
        return {
          fresh: 'api/fresh_spanish_chat',
          continuous: 'api/present_continue_spanish_chat',
        };
      case 'Hebrew':
        return {
          fresh: 'api/fresh_hebrew_chat',
          continuous: 'api/present_continue_hebrew_chat',
        };
      case 'English':
      default:
        return {
          fresh: 'api/fresh_chat',
          continuous: 'api/present_continue_chat',
        };
    }
  };

  useEffect(() => {
    const datafetch = async () => {
      setIsLoading(true);
      try {
        const generateSessionId = () => {
          return `${new Date().getTime()}-${Math.floor(Math.random() * 1000)}`;
        };

        // Call this function on login to generate and store the session ID
        const initializeSessionId = () => {
          const sessionId = generateSessionId();
          sessionStorage.setItem('sessionId', sessionId);
        };

        // Call initializeSessionId on login
        initializeSessionId();

        sessionStorage.setItem('loggedIn', true);

        await fetchAndRenderImage(user_name, setProfilePic);
        const time = await fetchAvailedTime(user_name);
        const voiceConsent = await fetchVoiceConcent(user_name);
        setConsent(voiceConsent.response); // Update consent state
        sessionStorage.setItem('consent', JSON.stringify(voiceConsent.response));

        const initialSub = await checkInitialSubscription(user_name);
        const centralSub = await checkCentralSubscription(user_name, email_id);
        sessionStorage.setItem('initial_subscription', JSON.stringify(initialSub));
        sessionStorage.setItem('central_subscription', JSON.stringify(centralSub));

        const language = await fetchCurrentLanguage(user_name);
        sessionStorage.setItem('current_language', language);

        const voiceChar = await fetchCharacterCode(user_name);
        const { fresh, continuous } = getEndpoints(language);

        const fontSize = await fetchFontSize(user_name);
        sessionStorage.setItem('font_size', fontSize);
        sessionStorage.setItem('freshEndpoint', fresh);
        sessionStorage.setItem('continuousEndpoint', continuous);
        sessionStorage.setItem('freshChat', '');

        const previousChatMessages = await fetchPreviousChat(user_name, 1, continuous);

        if (localStorage.getItem('Logged_by') === 'login') {
          navigate('/home');
        } else {
          localStorage.setItem('chatType', 'fresh');
          navigate('/home');
        }
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      } finally {
        setIsLoading(false);
      }
    };

    datafetch();
  }, [navigate, user_name, email_id]);

  return (
    <div className='loading-container'>
      <div className="loading-content">
        <img 
          src={img} 
          alt="Loading" 
          className="loading-image" 
        />
        <Typography variant="h7" gutterBottom className="loading-text">
          BAIA is setting up your profile to enhance your experience. Please wait a moment—this won't take long!
        </Typography>
      </div>

      {/* Conditionally render LocationFetcher based on loggedIn session value */}
      {sessionStorage.getItem('loggedIn') === 'true' && (
        <LocationFetcher userName={user_name} />
      )}
    </div>
  );
};

export default LoadingComponent;

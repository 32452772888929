// src/context/ProfilePicContext.js
import React, { createContext, useState, useContext } from 'react';
import initialImage from '../images/images.png'; // Adjust the path as needed

const ProfilePicContext = createContext();

export const useProfilePic = () => useContext(ProfilePicContext);

export const ProfilePicProvider = ({ children }) => {
  const [profilePic, setProfilePic] = useState(initialImage);

  return (
    <ProfilePicContext.Provider value={{ profilePic, setProfilePic }}>
      {children}
    </ProfilePicContext.Provider>
  );
};

// import React, { useState, useRef } from 'react';
// import PropTypes from 'prop-types';
// import { InputBase, IconButton, Popover, Box } from '@mui/material';
// import StopCircleIcon from '@mui/icons-material/StopCircle';
// import { EmojiEmotions as EmojiIcon, Send as SendIcon, Mic as MicIcon } from '@mui/icons-material';
// import Picker from '@emoji-mart/react';
// import { useTheme, useMediaQuery } from '@mui/material';
// import SubscriptionModal from '../utils/SubscriptionModal';
// import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';

// import nlp from 'compromise';

// const MessageInput = ({ onSend, isRenderingRef }) => {
//   const [message, setMessage] = useState('');
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [cursorPosition, setCursorPosition] = useState(0);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const abortControllerRef = useRef(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const recognitionRef = useRef(null);
//   const [isListening, setIsListening] = useState(false);
//   const [lastTranscript, setLastTranscript] = useState('');
//   const [selectedLanguage, setSelectedLanguage] = useState(sessionStorage.getItem('current_language') || 'English');

//   const languagesPool = {
//     French: "fr-FR",
//     English: "en-US",
//     Spanish: "es-ES",
//   };
//   const languageCode = languagesPool[selectedLanguage];

//   // // Initialize SpeechRecognition
//   // const initSpeechRecognition = () => {
//   //   if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
//   //     const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//   //     const recognition = new SpeechRecognition();
//   //     recognition.continuous = true; // Allow continuous speech input
//   //     recognition.interimResults = true; // Capture intermediate results
//   //       //   recognition.lang = 'en-US';
//   //     // recognition.lang = 'fr-FR'
//   //     recognition.lang = languageCode
  
//   //     recognition.onstart = () => {
//   //       setIsListening(true);
//   //     };
  
//   //     recognition.onresult = (event) => {
//   //       let interimTranscript = '';
//   //       let finalTranscript = '';
  
//   //       for (let i = event.resultIndex; i < event.results.length; i++) {
//   //         const result = event.results[i];
//   //         if (result.isFinal) {
//   //           finalTranscript += result[0].transcript;
//   //         } else {
//   //           interimTranscript += result[0].transcript;
//   //         }
//   //       }
  
//   //       // Update the message with final and interim transcripts
//   //       setMessage(finalTranscript + interimTranscript);
//   //     };
  
//   //     recognition.onend = () => {
//   //       setIsListening(false);
//   //       recognitionRef.current = null;
//   //     };
  
//   //     recognitionRef.current = recognition;
//   //   } else {
//   //     console.error('SpeechRecognition is not supported in this browser.');
//   //   }
//   // };


//   //  Code added by Gourav

//   //  Code start for photo upload

//   const handlephotochatUpload = () => {
//     const file = inputFileRef.current.files[0];
//     if (file.size > 10* 1024*1024) { 
//       showToast("File size limit exceeded");
//       return;
//     }

//     const reader = new FileReader();
//     reader.onload = (e) => {
//       const base64data = e.target.result;
//       // console.log(base64data)
//       const formData = {
//         base64data: base64data,
//         filename: file.name,
//         user_name: user_name  // You can dynamically set this value as needed
//       };

//       fetch(`${BASE_URL}/upload`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(formData)
//       })
//         .then(response => response.json())
//         .then(data => {
//           if (data.success) {
//             // console.log(data.response);
//             console.log("ImageUpdated");
//             fetchAndRenderImage(user_name);
//           } else {
//             showToast(data.error || "Failed to upload image");
//           }
//         })
//         .catch(error => {
//           console.error('Error:', error);
//           showToast('An error occurred while uploading the file.');
//         });
//     };
//     reader.readAsDataURL(file);
//   };

//   //  Code end for photo upload

//   const initSpeechRecognition = () => {
//     if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
//       const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//       const recognition = new SpeechRecognition();
//       recognition.continuous = true; // Allow continuous speech input
//       recognition.interimResults = true; // Capture intermediate results
//       //   recognition.lang = 'en-US';
//       // recognition.lang = 'fr-FR'
//       recognition.lang = languageCode;
  
//       recognition.onstart = () => {
//         setIsListening(true);
//       };
  
//       recognition.onresult = (event) => {
//         let interimTranscript = '';
//         let finalTranscript = '';
  
//         for (let i = event.resultIndex; i < event.results.length; i++) {
//           const result = event.results[i];
//           if (result.isFinal) {
//             finalTranscript += result[0].transcript;
//           } else {
//             interimTranscript += result[0].transcript;
//           }
//         }
  
//         // Apply basic grammar and punctuation correction
//         const correctedFinalTranscript = correctText(finalTranscript);
//         const correctedInterimTranscript = correctText(interimTranscript);
  
//         // Update the message with final and interim transcripts
//         setMessage(correctedFinalTranscript + correctedInterimTranscript);
//       };
  
//       recognition.onend = () => {
//         setIsListening(false);
//         recognitionRef.current = null;
//       };
  
//       recognitionRef.current = recognition;
//     } else {
//       console.error('SpeechRecognition is not supported in this browser.');
//     }
//   };
  
//   // Function to apply basic grammar and punctuation correction
//   const correctText = (text) => {
//     let doc = nlp(text);
    
//     // Convert the sentences to proper casing and add basic punctuation.
//     let correctedText = doc.sentences().toLowerCase().out('text'); // Ensure all text is lowercased.
//     correctedText = doc.sentences().toTitleCase().out('text'); // Correct casing for sentences.
    
//     // Alternatively, you can manually add periods at the end of sentences if they are missing.
//     // correctedText = correctedText.replace(/([a-zA-Z0-9])(\s|$)/g, '$1. ');
  
//     return correctedText.trim();
//   };
//   //  Code ends here 


//   const handleMicClick = () => {
//     if (recognitionRef.current) {
//       // Stop recognition if it's already running
//       recognitionRef.current.stop();
//     } else {
//       // Start recognition
//       initSpeechRecognition();
//       recognitionRef.current.start();
//     }
//   };

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const checkSubscriptionStatus = () => {
//     const subscriptionData = JSON.parse(sessionStorage.getItem('central_detail')) || {};
//     const intial_sub = JSON.parse(sessionStorage.getItem('initial_subscription')).enable_casual_chat_session_status;

//     if (intial_sub) {
//       return { canProceed: true, subscriptionData };
//     }

//     if (subscriptionData.subscription_status) {
//       if (subscriptionData.daily_time_limit_quota) {
//         return { canProceed: false, text: "Your daily time limit quota is over. Please subscribe to the Pro version." };
//       }

//       return { canProceed: true, subscriptionData };
//     }

//     return { canProceed: false, text: "No central subscription." };
//   };

//   const handleInputChange = (event) => {
//     setMessage(event.target.value);
//     setCursorPosition(event.target.selectionStart);
//   };

//   const handleKeyPress = (event) => {
//     const { canProceed } = checkSubscriptionStatus();

//     if (event.key === 'Enter') {
//       if (!canProceed) {
//         handleOpenModal();
//         return;
//       }
//       if (isMobile || event.shiftKey) {
//         event.preventDefault(); // Prevent default Enter behavior
//         const newMessage = message.slice(0, cursorPosition) + '\n' + message.slice(cursorPosition);
//         setMessage(newMessage);
//         setCursorPosition(cursorPosition + 1);
//       } else {
//         event.preventDefault();
//         handleSend();
//       }
//     }
//   };

//   const handleSend = () => {
//     const { canProceed } = checkSubscriptionStatus();

//     if (message.trim()) {
//       if (!canProceed) {
//         handleOpenModal();
//         return;
//       }
//       abortControllerRef.current = new AbortController();
//       onSend(message, abortControllerRef.current);
//       setMessage('');
//     }
//   };

//   const handleStopRendering = () => {
//     isRenderingRef.current = false;
//     sessionStorage.setItem('isRendering', 'false');
//     if (abortControllerRef.current) {
//       abortControllerRef.current.abort();
//     }
//   };

//   const handleOpenPopover = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClosePopover = () => {
//     setAnchorEl(null);
//   };

//   const handleEmojiSelect = (emoji) => {
//     const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
//     setMessage(newMessage);
//     setCursorPosition(cursorPosition + emoji.native.length);
//     handleClosePopover();
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? 'emoji-popover' : undefined;

//   const details_product_name=JSON.parse(sessionStorage.getItem('central_subscription')).product_name
//   const initial_details = JSON.parse(sessionStorage.getItem('initial_subscription'));
//   const initialcasualChatStatus = initial_details.enable_casual_chat_session_status;

//   const shouldShowMicIcon = (details_product_name === 'PRO' || details_product_name === 'ADVACED' || initialcasualChatStatus) &&
//                               sessionStorage.getItem('consent') === 'true';


//   return (
//     <div className="text-field">
//        <Popover
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClosePopover}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'left',
//         }}
//         transformOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//       >
//         <Box p={2}>
//           <Picker set="apple" onEmojiSelect={handleEmojiSelect} />
//         </Box>
//       </Popover>
//       <div className="input-container" style={{ display: 'flex', alignItems: 'center' }}>
//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             fontFamily: 'Roboto',
//             left: '0px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//           }}
//         >
//           <IconButton onClick={handleOpenPopover} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
//             <EmojiIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//           </IconButton>
//         </div>
//         <InputBase
//           placeholder="Type your message..."
//           value={message}
//           onChange={handleInputChange}
//           onKeyPress={handleKeyPress}
//           multiline
//           sx={{
//             zIndex: 9999,
//             flex: 1,
//             width: 'calc(100% - 130px)',
//             paddingLeft: 3,
//             minHeight: '51px',
//             maxHeight: '150px',
//             borderRadius: '20px',
//             backgroundColor: 'var(--color-white)',
//             fontSize: '16px',
//             padding: '5px 10px', // Adjust padding to reduce space
//             marginLeft: '20px',
//             marginRight: '100px',
//             overflowY: 'auto',
//             wordBreak: 'break-word',
//             fontFamily: 'Roboto',
//             lineHeight: 1.5, // Adjust line-height to reduce space between lines
//           }}
//         />
//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             right: '5px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//           }}
//           >
//           {shouldShowMicIcon && (
//             <IconButton
//             onClick={handleMicClick}
//             sx={{
//               fontSize: isMobile ? 'medium' : 'large',
//               borderRadius: '50%',
//               padding: isListening ? '8px' : '0',
//               backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
//               border: isListening ? '2px solid rgb(65, 6, 83)' : 'none',
//               '&:hover': {
//                 backgroundColor: isListening ? 'rgb(65, 6, 83)' : 'transparent',
//               },
//             }}
//           >
//             {isListening ? (
//               <MicIcon sx={{ color: 'white' }} />
//             ) : (
//               <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             )}
//           </IconButton>
//         )}

//           <IconButton
//             onClick={isRenderingRef.current ? handleStopRendering : handleSend}
//             sx={{ fontSize: isMobile ? 'medium' : 'large' }}
//           >
//             {isRenderingRef.current ? (
//               <StopCircleIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             ) : (
//               <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             )}
//           </IconButton>
//         </div>
//       </div>
//       <SubscriptionModal open={isModalOpen} onClose={handleCloseModal} />
//     </div>
//   );
// };

// MessageInput.propTypes = {
//   onSend: PropTypes.func.isRequired,
//   isRenderingRef: PropTypes.object.isRequired,
// };

// export default MessageInput;




// import React, { useState, useRef } from 'react';
// import PropTypes from 'prop-types';
// import { InputBase, IconButton, Popover, Box } from '@mui/material';
// import StopCircleIcon from '@mui/icons-material/StopCircle';
// import { EmojiEmotions as EmojiIcon, Send as SendIcon, Mic as MicIcon, AttachFile as AttachFileIcon } from '@mui/icons-material';
// import Picker from '@emoji-mart/react';
// import { useTheme, useMediaQuery } from '@mui/material';
// import SubscriptionModal from '../utils/SubscriptionModal';
// import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
// import { BASE_URL } from '../config';

// import nlp from 'compromise';

// const MessageInput = ({ onSend, isRenderingRef }) => {
//   const [message, setMessage] = useState('');
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [cursorPosition, setCursorPosition] = useState(0);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const abortControllerRef = useRef(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const recognitionRef = useRef(null);
//   const [isListening, setIsListening] = useState(false);
//   const [lastTranscript, setLastTranscript] = useState('');
//   const [selectedLanguage, setSelectedLanguage] = useState(sessionStorage.getItem('current_language') || 'English');
//   const inputFileRef = useRef(null); // Reference for file input
//   const sessionData =JSON.parse(sessionStorage.getItem('userInfo'))
//   const user_name = sessionData.username; 
//   const postfixedUserName = user_name + "_chatimage";

//   const languagesPool = {
//     French: "fr-FR",
//     English: "en-US",
//     Spanish: "es-ES",
//   };
//   const languageCode = languagesPool[selectedLanguage];

//   const handlephotochatUpload = () => {
//     const file = inputFileRef.current.files[0];
//     if (file.size > 10 * 1024 * 1024) {
//       alert("File size limit exceeded");
//       return;
//     }

//     const reader = new FileReader();
//     reader.onload = (e) => {
//       const base64data = e.target.result;
//       const formData = {
//         base64data: base64data,
//         filename: file.name,
//         user_name: postfixedUserName // Fetch username from session storage or fallback
//       };

//       fetch(`https://t3yb59mz86.l4b.ai/upload`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(formData)
//       })
//         .then(response => response.json())
//         .then(data => {
//           if (data.success) {
//             alert("Image uploaded successfully!");
//           } else {
//             alert(data.error || "Failed to upload image");
//           }
//         })
//         .catch(error => {
//           console.error('Error:', error);
//           alert('An error occurred while uploading the file.');
//         });
//     };
//     reader.readAsDataURL(file);
//   };

//   const initSpeechRecognition = () => {
//     if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
//       const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//       const recognition = new SpeechRecognition();
//       recognition.continuous = true;
//       recognition.interimResults = true;
//       recognition.lang = languageCode;

//       recognition.onstart = () => {
//         setIsListening(true);
//       };

//       recognition.onresult = (event) => {
//         let interimTranscript = '';
//         let finalTranscript = '';

//         for (let i = event.resultIndex; i < event.results.length; i++) {
//           const result = event.results[i];
//           if (result.isFinal) {
//             finalTranscript += result[0].transcript;
//           } else {
//             interimTranscript += result[0].transcript;
//           }
//         }

//         const correctedFinalTranscript = correctText(finalTranscript);
//         const correctedInterimTranscript = correctText(interimTranscript);

//         setMessage(correctedFinalTranscript + correctedInterimTranscript);
//       };

//       recognition.onend = () => {
//         setIsListening(false);
//         recognitionRef.current = null;
//       };

//       recognitionRef.current = recognition;
//     } else {
//       console.error('SpeechRecognition is not supported in this browser.');
//     }
//   };

//   const correctText = (text) => {
//     let doc = nlp(text);
//     let correctedText = doc.sentences().toLowerCase().out('text');
//     correctedText = doc.sentences().toTitleCase().out('text');
//     return correctedText.trim();
//   };

//   const handleMicClick = () => {
//     if (recognitionRef.current) {
//       recognitionRef.current.stop();
//     } else {
//       initSpeechRecognition();
//       recognitionRef.current.start();
//     }
//   };

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const checkSubscriptionStatus = () => {
//     const subscriptionData = JSON.parse(sessionStorage.getItem('central_detail')) || {};
//     const initial_sub = JSON.parse(sessionStorage.getItem('initial_subscription')).enable_casual_chat_session_status;

//     if (initial_sub) {
//       return { canProceed: true, subscriptionData };
//     }

//     if (subscriptionData.subscription_status) {
//       if (subscriptionData.daily_time_limit_quota) {
//         return { canProceed: false, text: "Your daily time limit quota is over. Please subscribe to the Pro version." };
//       }

//       return { canProceed: true, subscriptionData };
//     }

//     return { canProceed: false, text: "No central subscription." };
//   };

//   const handleInputChange = (event) => {
//     setMessage(event.target.value);
//     setCursorPosition(event.target.selectionStart);
//   };

//   const handleKeyPress = (event) => {
//     const { canProceed } = checkSubscriptionStatus();

//     if (event.key === 'Enter') {
//       if (!canProceed) {
//         handleOpenModal();
//         return;
//       }
//       if (isMobile || event.shiftKey) {
//         event.preventDefault();
//         const newMessage = message.slice(0, cursorPosition) + '\n' + message.slice(cursorPosition);
//         setMessage(newMessage);
//         setCursorPosition(cursorPosition + 1);
//       } else {
//         event.preventDefault();
//         handleSend();
//       }
//     }
//   };

//   const handleSend = () => {
//     const { canProceed } = checkSubscriptionStatus();

//     if (message.trim()) {
//       if (!canProceed) {
//         handleOpenModal();
//         return;
//       }
//       abortControllerRef.current = new AbortController();
//       onSend(message, abortControllerRef.current);
//       setMessage('');
//     }
//   };

//   const handleStopRendering = () => {
//     isRenderingRef.current = false;
//     sessionStorage.setItem('isRendering', 'false');
//     if (abortControllerRef.current) {
//       abortControllerRef.current.abort();
//     }
//   };

//   const handleOpenPopover = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClosePopover = () => {
//     setAnchorEl(null);
//   };

//   const handleEmojiSelect = (emoji) => {
//     const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
//     setMessage(newMessage);
//     setCursorPosition(cursorPosition + emoji.native.length);
//     handleClosePopover();
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? 'emoji-popover' : undefined;

//   const details_product_name = JSON.parse(sessionStorage.getItem('central_subscription')).product_name;
//   const initial_details = JSON.parse(sessionStorage.getItem('initial_subscription'));
//   const initialcasualChatStatus = initial_details.enable_casual_chat_session_status;

//   const shouldShowMicIcon = (details_product_name === 'PRO' || details_product_name === 'ADVACED' || initialcasualChatStatus) &&
//     sessionStorage.getItem('consent') === 'true';

//   return (
//     <div className="text-field">
//       <Popover
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClosePopover}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'left',
//         }}
//         transformOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//       >
//         <Box p={2}>
//           <Picker set="apple" onEmojiSelect={handleEmojiSelect} />
//         </Box>
//       </Popover>
//       <div className="input-container" style={{ display: 'flex', alignItems: 'center' }}>
//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             fontFamily: 'Roboto',
//             left: '0px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//           }}
//         >
//           <IconButton onClick={handleOpenPopover} sx={{ fontSize: isMobile ? 'medium' : 'large' }}>
//             <EmojiIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//           </IconButton>

//           {/* File Upload Button */}
//           <input
//             type="file"
//             ref={inputFileRef}
//             style={{ display: 'none' }}
//             onChange={handlephotochatUpload}
//           />
//           <IconButton
//             onClick={() => inputFileRef.current.click()}
//             sx={{ fontSize: isMobile ? 'medium' : 'large' }}
//           >
//             <AttachFileIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//           </IconButton>

//           {shouldShowMicIcon && (
//             <IconButton
//               onClick={handleMicClick}
//               sx={{ fontSize: isMobile ? 'medium' : 'large' }}
//             >
//               {isListening ? (
//                 <MicIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//               ) : (
//                 <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//               )}
//             </IconButton>
//           )}
//         </div>

//         <InputBase
//           placeholder="Type your message..."
//           value={message}
//           onChange={handleInputChange}
//           onKeyPress={handleKeyPress}
//           multiline
//           sx={{
//             flex: 1,
//             width: 'calc(100% - 130px)',
//             paddingLeft: 3,
//             minHeight: '51px',
//             maxHeight: '150px',
//             borderRadius: '20px',
//             backgroundColor: 'var(--color-white)',
//             fontSize: '16px',
//             padding: '5px 10px',
//             marginLeft: '20px',
//             marginRight: '100px',
//             overflowY: 'auto',
//             wordBreak: 'break-word',
//             fontFamily: 'Roboto',
//             lineHeight: 1.5,
//           }}
//         />

//         <div
//           className="icons-container"
//           style={{
//             position: 'absolute',
//             right: '5px',
//             bottom: '5px',
//             display: 'flex',
//             alignItems: 'center',
//             zIndex: 1,
//           }}
//         >
//           <IconButton
//             onClick={isRenderingRef.current ? handleStopRendering : handleSend}
//             sx={{ fontSize: isMobile ? 'medium' : 'large' }}
//           >
//             {isRenderingRef.current ? (
//               <StopCircleIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             ) : (
//               <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
//             )}
//           </IconButton>
//         </div>
//       </div>

//       <SubscriptionModal open={isModalOpen} onClose={handleCloseModal} />
//     </div>
//   );
// };

// MessageInput.propTypes = {
//   onSend: PropTypes.func.isRequired,
//   isRenderingRef: PropTypes.object.isRequired,
// };

// export default MessageInput;

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputBase, IconButton, Popover, Box } from '@mui/material';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { EmojiEmotions as EmojiIcon, Send as SendIcon, Mic as MicIcon, AttachFile as AttachFileIcon, Close as CloseIcon } from '@mui/icons-material';
import Picker from '@emoji-mart/react';
import { useTheme, useMediaQuery } from '@mui/material';
import SubscriptionModal from '../utils/SubscriptionModal';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import * as nsfwjs from 'nsfwjs'; // Import NSFW.js


const MessageInput = ({ onSend, isRenderingRef }) => {
  const [message, setMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const abortControllerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const recognitionRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const inputFileRef = useRef(null);
  const [model, setModel] = useState(null); // State to hold the NSFW.js model

  const sessionData = JSON.parse(sessionStorage.getItem('userInfo'));
  const user_name = sessionData.username;
  const postfixedUserName = `${user_name}_chatimage`;

  // Show Mic Icon logic
  const details_product_name = JSON.parse(sessionStorage.getItem('central_subscription')).product_name;
  const initial_details = JSON.parse(sessionStorage.getItem('initial_subscription'));
  const initialcasualChatStatus = initial_details.enable_casual_chat_session_status;

  const shouldShowMicIcon = (details_product_name === 'PRO' || details_product_name === 'ADVACED' || initialcasualChatStatus) &&
    sessionStorage.getItem('consent') === 'true';

    useEffect(() => {
      const loadModel = async () => {
        const loadedModel = await nsfwjs.load();
        setModel(loadedModel);
      };
      loadModel();
    }, []);

  const checkSubscriptionStatus = () => {
    const subscriptionData = JSON.parse(sessionStorage.getItem('central_detail')) || {};
    const intial_sub = JSON.parse(sessionStorage.getItem('initial_subscription')).enable_casual_chat_session_status;

    if (intial_sub) {
      return { canProceed: true, subscriptionData };
    }

    if (subscriptionData.subscription_status) {
      if (subscriptionData.daily_time_limit_quota) {
        return { canProceed: false, text: "Your daily time limit quota is over. Please subscribe to the Pro version." };
      }

      return { canProceed: true, subscriptionData };
    }

    return { canProceed: false, text: "No central subscription." };
  };

  // Function to handle microphone click
  const handleMicClick = () => {
    if (isListening) {
      recognitionRef.current.stop();
      setIsListening(false);
    } else {
      if (!('webkitSpeechRecognition' in window)) {
        alert('Speech recognition not supported in your browser. Please use Google Chrome.');
        return;
      }

      recognitionRef.current = new window.webkitSpeechRecognition();
      recognitionRef.current.lang = 'en-US';
      recognitionRef.current.interimResults = false;
      recognitionRef.current.maxAlternatives = 1;

      recognitionRef.current.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setMessage(prev => prev + (prev ? ' ' : '') + transcript);
        setCursorPosition(message.length + transcript.length);
      };

      recognitionRef.current.onend = () => {
        setIsListening(false);
      };

      recognitionRef.current.start();
      setIsListening(true);
    }
  };

  const detectNudity = async (file) => {
    if (!model) {
      console.error("Model not loaded");
      return false; // Model is not loaded
    }
  
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async (event) => {
        const img = new Image();
        img.src = event.target.result;
  
        img.onload = async () => {
          const predictions = await model.classify(img);
  
          // Log the first prediction
          const firstPrediction = predictions[0];
          console.log("First detected class:", firstPrediction.className, "with probability:", firstPrediction.probability);
  
          // Check if the first prediction is Porn or Hentai
          const nudityDetected = (firstPrediction.className === 'Porn' || firstPrediction.className === 'Hentai') && firstPrediction.probability > 0.5;
          
          resolve(nudityDetected);
        };
  
        img.onerror = () => reject('Error loading image');
      };
  
      reader.readAsDataURL(file);
    });
  };
  

  
  // const detectNudity = async (file) => {
  //   if (!model) {
  //     console.error("Model not loaded");
  //     return false; // Model is not loaded
  //   }
  
  //   const reader = new FileReader();
  //   return new Promise((resolve, reject) => {
  //     reader.onload = async (event) => {
  //       const img = new Image();
  //       img.src = event.target.result;
  
  //       img.onload = async () => {
  //         const predictions = await model.classify(img);
  
  //         // Log predictions
  //         predictions.forEach(prediction => {
  //           console.log("Image detected class:", prediction.className, "with probability:", prediction.probability);
  //         });
  
  //         // Check if Porn or Sexy content is detected with a high probability (>0.7)
  //         const nudityDetected = predictions.some(prediction =>
  //           (prediction.className === 'Porn' || prediction.className === 'Hentai')
  //         );
  //         // const nudityDetected = predictions.some(prediction =>
  //         //   (prediction.className === 'Porn' || prediction.className === 'Sexy') && prediction.probability > 0.7
  //         // );
  //         resolve(nudityDetected);
  //       };
  
  //       img.onerror = () => reject('Error loading image');
  //     };
  
  //     reader.readAsDataURL(file);
  //   });
  // };
  
  // Function to handle image upload and preview
  const handlePhotoChatUpload = async () => {
    const file = inputFileRef.current.files[0];
    if (file && file.size > 10 * 1024 * 1024) {
      alert("File size limit exceeded");
      return;
    }

    if (file) {
      const nudityDetected = await detectNudity(file);
      if (nudityDetected) {
        alert("The image contains content that is against our policy and will not be uploaded.");
        setSelectedFile(null);
      } else {
        setSelectedFile(file);
      }
    }
  };
  // const handlePhotoChatUpload = () => {
  //   const file = inputFileRef.current.files[0];
  //   if (file && file.size > 10 * 1024 * 1024) {
  //     alert("File size limit exceeded");
  //     return;
  //   }

  //   if (file) {
  //     setSelectedFile(file);
  //   }
  // };

  // Function to send message and image
  const handleSend = async () => {
    const { canProceed, text } = checkSubscriptionStatus();

    if (message.trim() || selectedFile) {
      if (!canProceed) {
        handleOpenModal(text);
        return;
      }

      let imageUrl = null;

      if (selectedFile) {
        try {
          imageUrl = await uploadImage(); // Upload the image first
        } catch (error) {
          alert(error);
          return;
        }
      }

      // Send the message with image URL (if available)
      onSend({
        text: message,
        imageUrl: imageUrl || null, // Use the uploaded image URL if available
      }, abortControllerRef.current);

      setMessage('');  // Clear the message input
      setSelectedFile(null); // Clear the selected image after sending
    }
  };

  // Function to upload image to the server
  const uploadImage = async () => {
    if (!selectedFile) return null;

    setIsUploading(true);

    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async (e) => {
        const base64data = e.target.result;
        const formData = {
          base64data: base64data,
          filename: selectedFile.name,
          user_name: postfixedUserName,
        };

        try {
          const response = await fetch('https://t3yb59mz86.l4b.ai/upload', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });

          const data = await response.json();

          if (data.success) {

            console.log("data image:- ", data)
            resolve(data.response); // Use 'data.response' to get the S3 URL
          } else {
            reject(data.error || 'Failed to upload image');
          }
        } catch (error) {
          console.error('Error:', error);
          reject('An error occurred while uploading the file.');
        } finally {
          setIsUploading(false);
        }
      };

      reader.readAsDataURL(selectedFile);
    });
  };

  // Function to remove selected image
  const handleRemoveImage = () => {
    setSelectedFile(null);
  };

  // Function to handle Enter key press
  const handleKeyPress = (event) => {
    const { canProceed, text } = checkSubscriptionStatus();

    if (event.key === 'Enter') {
      if (!canProceed) {
        handleOpenModal(text);
        return;
      }
      if (!event.shiftKey && !isMobile) {
        event.preventDefault();
        handleSend();
      }
    }
  };

  // Function to handle opening the subscription modal
  const handleOpenModal = (message) => {
    alert(message); // This can be replaced with a modal dialog
    setIsModalOpen(true);
  };

  return (
    <div className="text-field">
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <Picker set="apple" onEmojiSelect={(emoji) => {
            const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
            setMessage(newMessage);
            setCursorPosition(cursorPosition + emoji.native.length);
          }} />
        </Box>
      </Popover>
  
      <div className="input-container" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <div style={{ flex: 1, marginRight: '100px' }}>
          {/* Display the selected image */}
          {selectedFile && (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Selected"
                style={{ maxHeight: '60px', marginRight: '10px', borderRadius: '8px' }} 
              />
              {/* Reduced image height */}

              
              <IconButton onClick={handleRemoveImage}>
                <CloseIcon sx={{ color: 'red' }} />
              </IconButton>
            </div>
          )}
  
          <InputBase
            placeholder="Type a message..."
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            onKeyPress={handleKeyPress}
            multiline
            sx={{
              width: '100%',
              maxWidth: '1000px',
              padding: '6px',  // Reduced padding
              backgroundColor: 'white',
              borderRadius: '15px',  // Slightly more compact border-radius
              minHeight: '40px',  // Reduced minimum height
              fontSize: '15px',   // Slightly smaller font size
              lineHeight: 1.4,    // Reduced line height for compactness
              overflowY: 'auto',
              fontFamily: 'Roboto',
            }}
          />
        </div>
  
        <div className="icons-container" style={{ position: 'absolute', right: '15px', bottom: '5px', display: 'flex', alignItems: 'center', zIndex: 1 }}>
          {/* Emoji Picker */}
          <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
            <EmojiIcon sx={{ color: 'rgb(65, 6, 83)' }} />
          </IconButton>
  
          {/* File Upload Button */}
          <input
            type="file"
            ref={inputFileRef}
            style={{ display: 'none' }}
            onChange={handlePhotoChatUpload}
          />
          <IconButton onClick={() => inputFileRef.current.click()}>
            <AttachFileIcon sx={{ color: 'rgb(65, 6, 83)' }} />
          </IconButton>
  
          {/* Microphone Icon */}
          {shouldShowMicIcon && (
            <IconButton onClick={handleMicClick}>
              {isListening ? (
                <MicIcon sx={{ color: 'rgb(65, 6, 83)' }} />
              ) : (
                <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
              )}
            </IconButton>
          )}
  
          {/* Send Button */}
          <IconButton onClick={handleSend} disabled={isUploading}>
            {isUploading ? (
              <StopCircleIcon sx={{ color: 'gray' }} />
            ) : (
              <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
            )}
          </IconButton>
        </div>
      </div>
  
      <SubscriptionModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
  

  // return (
  //   <div className="text-field">
  //     <Popover
  //       open={Boolean(anchorEl)}
  //       anchorEl={anchorEl}
  //       onClose={() => setAnchorEl(null)}
  //       anchorOrigin={{
  //         vertical: 'top',
  //         horizontal: 'left',
  //       }}
  //       transformOrigin={{
  //         vertical: 'bottom',
  //         horizontal: 'left',
  //       }}
  //     >
  //       <Box p={2}>
  //         <Picker set="apple" onEmojiSelect={(emoji) => {
  //           const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
  //           setMessage(newMessage);
  //           setCursorPosition(cursorPosition + emoji.native.length);
  //         }} />
  //       </Box>
  //     </Popover>
  
  //     <div className="input-container" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
  //       <div style={{ flex: 1, marginRight: '100px' }}>
  //         {/* Display the selected image */}
  //         {selectedFile && (
  //           <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
  //             <img
  //               src={URL.createObjectURL(selectedFile)}
  //               alt="Selected"
  //               style={{ maxHeight: '100px', marginRight: '10px', borderRadius: '10px' }}
  //             />
  //             <IconButton onClick={handleRemoveImage}>
  //               <CloseIcon sx={{ color: 'red' }} />
  //             </IconButton>
  //           </div>
  //         )}
  
  //         <InputBase
  //           placeholder="Type a message..."
  //           value={message}
  //           onChange={(event) => setMessage(event.target.value)}
  //           onKeyPress={handleKeyPress}
  //           multiline
  //           sx={{
  //             width: '100%',
  //             maxWidth: '1000px',
  //             padding: '8px',
  //             backgroundColor: 'white',
  //             borderRadius: '20px',
  //             minHeight: '50px',
  //             fontSize: '16px',
  //             lineHeight: 1.5,
  //             overflowY: 'auto',
  //             fontFamily: 'Roboto',
  //           }}
  //         />
  //       </div>
  
  //       <div className="icons-container" style={{ position: 'absolute', right: '15px', bottom: '5px', display: 'flex', alignItems: 'center', zIndex: 1 }}>
  //         {/* Emoji Picker */}
  //         <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
  //           <EmojiIcon sx={{ color: 'rgb(65, 6, 83)' }} />
  //         </IconButton>
  
  //         {/* File Upload Button */}
  //         <input
  //           type="file"
  //           ref={inputFileRef}
  //           style={{ display: 'none' }}
  //           onChange={handlePhotoChatUpload}
  //         />
  //         <IconButton onClick={() => inputFileRef.current.click()}>
  //           <AttachFileIcon sx={{ color: 'rgb(65, 6, 83)' }} />
  //         </IconButton>
  
  //         {/* Microphone Icon */}
  //         {shouldShowMicIcon && (
  //           <IconButton onClick={handleMicClick}>
  //             {isListening ? (
  //               <MicIcon sx={{ color: 'rgb(65, 6, 83)' }} />
  //             ) : (
  //               <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
  //             )}
  //           </IconButton>
  //         )}
  
  //         {/* Send Button */}
  //         <IconButton onClick={handleSend} disabled={isUploading}>
  //           {isUploading ? (
  //             <StopCircleIcon sx={{ color: 'gray' }} />
  //           ) : (
  //             <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
  //           )}
  //         </IconButton>
  //       </div>
  //     </div>
  
  //     <SubscriptionModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
  //   </div>
  // );
  

  // return (
  //   <div className="text-field">
  //     <Popover
  //       open={Boolean(anchorEl)}
  //       anchorEl={anchorEl}
  //       onClose={() => setAnchorEl(null)}
  //       anchorOrigin={{
  //         vertical: 'top',
  //         horizontal: 'left',
  //       }}
  //       transformOrigin={{
  //         vertical: 'bottom',
  //         horizontal: 'left',
  //       }}
  //     >
  //       <Box p={2}>
  //         <Picker set="apple" onEmojiSelect={(emoji) => {
  //           const newMessage = message.slice(0, cursorPosition) + emoji.native + message.slice(cursorPosition);
  //           setMessage(newMessage);
  //           setCursorPosition(cursorPosition + emoji.native.length);
  //         }} />
  //       </Box>
  //     </Popover>

  //     <div className="input-container" style={{ display: 'flex', alignItems: 'center' }}>
  //       <div className="icons-container" style={{ position: 'absolute', left: '0px', bottom: '5px', display: 'flex', alignItems: 'center', zIndex: 1 }}>
  //         <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
  //           <EmojiIcon sx={{ color: 'rgb(65, 6, 83)' }} />
  //         </IconButton>

  //         {/* File Upload Button */}
  //         <input
  //           type="file"
  //           ref={inputFileRef}
  //           style={{ display: 'none' }}
  //           onChange={handlePhotoChatUpload}
  //         />
  //         <IconButton onClick={() => inputFileRef.current.click()}>
  //           <AttachFileIcon sx={{ color: 'rgb(65, 6, 83)' }} />
  //         </IconButton>

  //         {shouldShowMicIcon && (
  //           <IconButton onClick={handleMicClick}>
  //             {isListening ? (
  //               <MicIcon sx={{ color: 'rgb(65, 6, 83)' }} />
  //             ) : (
  //               <MicNoneOutlinedIcon sx={{ color: 'rgb(65, 6, 83)' }} />
  //             )}
  //           </IconButton>
  //         )}
  //       </div>

  //       <div style={{ flex: 1, marginLeft: '20px' }}>
  //         {/* Display the selected image */}
  //         {selectedFile && (
  //           <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
  //             <img
  //               src={URL.createObjectURL(selectedFile)}
  //               alt="Selected"
  //               style={{ maxHeight: '100px', marginRight: '10px', borderRadius: '10px' }}
  //             />
  //             <IconButton onClick={handleRemoveImage}>
  //               <CloseIcon sx={{ color: 'red' }} />
  //             </IconButton>
  //           </div>
  //         )}

  //         <InputBase
  //           placeholder="Type a message..."
  //           value={message}
  //           onChange={(event) => setMessage(event.target.value)}
  //           onKeyPress={handleKeyPress}
  //           multiline
  //           sx={{
            
  //               // flex: 1,
  //               // paddingLeft: '50px',
  //               // minHeight: '51px',
  //               // maxHeight: '150px',
  //               // borderRadius: '20px',
  //               // backgroundColor: 'var(--color-white)',
  //               // fontSize: '16px',
  //               // padding: '5px 10px',
  //               // marginLeft: '100px',
  //               // marginRight: '100px',
  //               // overflowY: 'auto',
  //               // wordBreak: 'break-word',
  //               // fontFamily: 'Roboto',
  //               // lineHeight: 1.5,
              
  //             width: '100%',
  //             maxWidth: '1000px', // Set a max width to prevent overextension
  //             padding: '8px',
  //             paddingLeft: '50px',
  //             backgroundColor: 'white',
  //             borderRadius: '20px',
  //             minHeight: '50px',
  //             marginLeft: '75px',
  //             marginRight: '200px',
  //             fontSize: '16px',
  //             lineHeight: 1.5,
  //             overflowY: 'auto', 
  //             fontFamily: 'Roboto',
  //           }}
  //         />
  //       </div>

  //       <div className="icons-container" style={{ position: 'absolute', right: '15px', bottom: '5px', display: 'flex', alignItems: 'center', zIndex: 1 }}>
  //         <IconButton onClick={handleSend} disabled={isUploading}>
  //           {isUploading ? (
  //             <StopCircleIcon sx={{ color: 'gray' }} />
  //           ) : (
  //             <SendIcon sx={{ color: 'rgb(65, 6, 83)' }} />
  //           )}
  //         </IconButton>
  //       </div>
  //     </div>

  //     <SubscriptionModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
  //   </div>
  // );
};

MessageInput.propTypes = {
  onSend: PropTypes.func.isRequired,
  isRenderingRef: PropTypes.object.isRequired,
};

export default MessageInput;


// src/context/AssessmentContext.js

import React, { createContext, useState, useContext } from 'react';

const AssessmentContext = createContext();

export const AssessmentProvider = ({ children }) => {
  const [assessmentResult, setAssessmentResult] = useState(null);

  return (
    <AssessmentContext.Provider value={{ assessmentResult, setAssessmentResult }}>
      {children}
    </AssessmentContext.Provider>
  );
};

export const useAssessment = () => useContext(AssessmentContext);

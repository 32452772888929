


import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element: Component }) => {
  const isLoggedIn = JSON.parse(sessionStorage.getItem('loggedIn'));

  return isLoggedIn ? Component : <Navigate to="/" />;
};

export default ProtectedRoute;
